<template>
  <!-- Filtros de preferências -->
  <div class="filtros" :class="desktopUserPreferencesCss">
    <div class="overlay">
      <Tips
        ref="tips"
        :title="'Dicas'"
        :contents="['Ative e desative as praças que desejar clicando nas tags. As praças ativas estarão com a cor laranja.', 'ALOHAAA']"
      />
      <div class="content">
        <!-- Header -->
        <div class="header">
          <i class="icone material-icons">
            tune
          </i>
          <h1>Preferências</h1>
          <div class="buttons">
            <button type="button" class="btn btn_individual btn_secondary ripple"
                    @click="applyPreferences()"
            >
              <i class="icone material-icons">
                close
              </i>
            </button>
          </div>
        </div>
        <!-- // -->

        <!-- Middle -->
        <div class="middle">
          <!-- <div class="description">
            Defina as configurações de sua preferência.
            Escolha quais praças e emissoras deseja visualizar,
            além da ordem que será exibido na tabela.
          </div> -->

          <!-- Options : Praças -->
          <div class="options">
            <h1>
              <i class="icone material-icons">
                pin_drop
              </i>Praças

              <!-- <button type="button" class="btn help" @click="openTips()">
                <i class="icone material-icons">
                  help
                </i>
              </button> -->
            </h1>
            <div class="area draggableList">
              <draggable v-model="marketIdsEnabled"
                         :options="{
                           draggable:'.draggableItem',
                           emptyInsertThreshold: 20,
                           removeCloneOnHide: true,
                           animation: 80,
                           bubbleScroll: true,
                           ghostClass: 'ghost-background',
                         }"
                         @start="drag=true" @end="drag=false"
              >
                <button v-for="market in marketsEnabled" :key="market.id"
                        type="button" class="btn tag_item ripple draggableItem"
                        @click="changeMarketStatus(market.id)"
                >
                  {{ market.exhibitionName }}
                  <i class="icone material-icons">
                    radio_button_checked
                  </i>
                </button>

                <!-- DISABLED -->
                <button v-for="market in marketsDisabled"
                        slot="footer" :key="market.id"
                        type="button" class="btn tag_item ripple disabled"
                        @click="changeMarketStatus(market.id)"
                >
                  {{ market.exhibitionName }}
                  <i class="icone material-icons">
                    radio_button_unchecked
                  </i>
                </button>
              </draggable>
            </div>
          </div>
          <!-- // -->

          <div class="divisor" />

          <!-- Options : Emissoras -->
          <div class="options">
            <h1>
              <i class="icone material-icons">
                tv
              </i>Emissoras

              <!-- <button type="button" class="btn help">
                <i class="icone material-icons">
                  help
                </i>
              </button> -->
            </h1>
            <div class="area draggableList">
              <draggable v-model="tvNetworkIdsEnabled"
                         :move="handleMove"
                         :options="{
                           draggable:'.draggableItem',
                           emptyInsertThreshold: 20,
                           removeCloneOnHide: true,
                           animation: 80,
                           bubbleScroll: true,
                           ghostClass: 'ghost-background'
                         }"
                         @start="drag=true" @end="drag=false"
              >
                <button v-for="tvNetworkId in tvNetworksEnabled"
                        :key="tvNetworkId" type="button"
                        class="btn tag_item ripple"
                        :class="{
                          especial: tvNetworkId === tvNetworksEnabled[0],
                          draggableItem: (tvNetworkId !== 'TL'
                            && tvNetworkId !== 'TLE')
                        }"
                        @click="changeTvNetworkStatus(tvNetworkId)"
                >
                  {{ transNetworkNameById(tvNetworkId) }}
                  <i class="icone material-icons">
                    {{ tvNetworkId === tvNetworksEnabled[0] ? 'stars' : 'radio_button_checked' }}
                  </i>
                </button>
                <!-- DISABLED -->
                <button v-for="tvNetworkId in tvNetworksDisabled" slot="footer" :key="tvNetworkId"
                        type="button" class="btn tag_item ripple disabled"
                        @click="changeTvNetworkStatus(tvNetworkId)"
                >
                  {{ transNetworkNameById(tvNetworkId) }}
                  <i class="icone material-icons">
                    radio_button_unchecked
                  </i>
                </button>
              </draggable>
            </div>
          </div>
          <!-- // -->

          <div class="divisor last" />

          <div class="options">
            <h1>
              <i class="icone material-icons">
                add_box
              </i>
              Complementos

              <!-- <button type="button" class="btn help">
                <i class="icone material-icons">
                  help
                </i>
              </button> -->
            </h1>
            <div v-if="settings.hasSampling" class="item">
              <i class="icone material-icons">
                group
              </i>
              Amostra

              <label class="switch">
                <input :checked="isShowSampling"
                       type="checkbox" @change="changeShowSampling()"
                >
                <span class="slider round ripple" />
              </label>
            </div>

            <div class="item">
              <i class="icone material-icons">
                view_week
              </i>
              Programação concorrente

              <label class="switch">
                <input :checked="isShowCompetitor"
                       type="checkbox" @change="changeShowCompetitor()"
                >
                <span class="slider round ripple" />
              </label>
            </div>

            <div class="item">
              <i class="icone material-icons">
                view_week
              </i>
              Programação personalizada

              <label class="switch">
                <input :checked="usePrograms"
                       type="checkbox" @change="changeUsePrograms()"
                >
                <span class="slider round ripple" />
              </label>

              <div v-if="usePrograms === true" class="area">
                <button v-for="market in customProgramsMarketsEnabled" :key="market.id"
                        type="button" class="btn tag_item ripple"
                        @click="changeCustomProgramsMarketStatus(market.id)"
                >
                  {{ market.exhibitionName }}
                  <i class="icone material-icons">
                    radio_button_checked
                  </i>
                </button>

                <!-- DISABLED -->
                <button v-for="market in customProgramsMarketsDisabled"
                        slot="footer" :key="market.id"
                        type="button" class="btn tag_item ripple disabled"
                        @click="changeCustomProgramsMarketStatus(market.id)"
                >
                  {{ market.exhibitionName }}
                  <i class="icone material-icons">
                    radio_button_unchecked
                  </i>
                </button>
              </div>
            </div>

            <div class="item">
              <i class="icone material-icons">
                view_week
              </i>
              Programação segmentada

              <label class="switch">
                <input :disabled="isScheduleDisabled" :checked="useSchedule"
                       type="checkbox" @change="changeUseSchedule()"
                >
                <span class="slider round ripple" />
              </label>
            </div>
            <!-- Peso das praças -->
            <div class="item">
              <i class="icone material-icons">
                fitness_center
              </i>
              Peso das praças
              <label class="switch">
                <input :checked="isMarketsWeightVisible" type="checkbox" @change="changeMarketsWeightStatus">
                <span class="slider round ripple" />
              </label>
            </div>
          </div>
          <!-- // -->

          <div class="divisor last" />

          <div class="options">
            <h1>
              <i class="icone material-icons">
                timelapse
              </i>
              Dados

              <!-- <button type="button" class="btn help">
                <i class="icone material-icons">
                  help
                </i>
              </button> -->
            </h1>
            <div class="item">
              <i class="icone material-icons">
                event_seat
              </i>
              Audiência

              <label class="switch">
                <input :checked="defaultValue === 'audience'"
                       type="radio" @change="changeDefaultValue('audience')"
                >
                <span class="slider round ripple" />
              </label>
            </div>

            <div v-if="settings.hasTLE" class="item" :class="{ 'disabled': settings.isIndividualsView }">
              <i class="icone material-icons">
                donut_large
              </i>
              Share TLE

              <label class="switch">
                <input :checked="defaultValue === 'shareTLE'"
                       type="radio" @change="changeDefaultValue('shareTLE')"
                >
                <span class="slider round ripple" />
              </label>
            </div>

            <div v-if="settings.hasTLE" class="item" :class="{ 'disabled': settings.isIndividualsView }">
              <div class="stacked-icons">
                <i class="icone material-icons">
                  donut_large
                </i>
                <i class="icone material-icons">
                  event_seat
                </i>
              </div>
              Audiência e Share TLE

              <label class="switch">
                <input
                  :checked="defaultValue === 'audienceAndShareTLE'"
                  type="radio" @change="changeDefaultValue('audienceAndShareTLE')"
                >
                <span class="slider round ripple" />
              </label>
            </div>

            <div v-if="settings.hasTL" class="item disabled">
              <i class="icone material-icons">
                donut_small
              </i>
              Share TL

              <label class="switch">
                <input
                  :checked="defaultValue === 'shareTL'"
                  type="radio" @change="changeDefaultValue('shareTL')"
                >
                <span class="slider round ripple" />
              </label>
            </div>

            <p class="disclaimer" v-if="defaultValue === 'audienceAndShareTLE'">
              Só é possível visualizar a audiência e share juntos na visão de tabela. Nas demais visões, será exibida apenas a audiência.
            </p>

            <p v-if="settings.isIndividualsView" class="disclaimer">
              No universo "Individual (#)" só é possível visualizar a audiência em absoluto.
            </p>
          </div>


          <!-- dados simulcast -->
          <div  class="divisor last" />

          <div v-if="settings.hasSimuCast" class="options">
            <h1>
              <i class="icone material-icons">
                timelapse
              </i>
              Dados Simulcast
            </h1>
            <div v-if="settings.hasSimuCast" class="item">
              <i class="icone material-icons">
                person
              </i>

              Usuários conectados

              <label class="switch">
                <input :checked="simulcastValue === 'users'"
                  type="radio" @change="changeSimulcastValue('users')"
                >
                <span class="slider round ripple" />
              </label>
            </div>

            <div  v-if="settings.hasSimuCast" class="item">
              <i class="icone material-icons">
                event_seat
              </i>

              Audiência

              <label class="switch">
                <input :checked="simulcastValue === 'audience'"
                  type="radio" @change="changeSimulcastValue('audience')"
                >
                <span class="slider round ripple" />
              </label>
            </div>
          </div>

          <!-- Fim dados simulcast -->
          <div class="divisor last" />

          <div class="options">
            <h1>
              <i class="icone material-icons">
                show_chart
              </i>Média Histórica

              <!-- <button type="button" class="btn help" @click="openTips()">
                <i class="icone material-icons">
                  help
                </i>
              </button> -->
            </h1>
            <div class="item">
              <i class="icone material-icons">
                view_stream
              </i>
              2 Semanas

              <label class="switch">
                <input :checked="performanceHistory === 2"
                       type="radio" @change="changePerformanceHistory(2)"
                >
                <span class="slider round ripple" />
              </label>
            </div>

            <div class="item">
              <i class="icone material-icons">
                view_headline
              </i>
              4 Semanas

              <label class="switch">
                <input :checked="performanceHistory === 4"
                       type="radio" @change="changePerformanceHistory(4)"
                >
                <span class="slider round ripple" />
              </label>
            </div>
          </div>
          <!-- // -->
        </div>
        <!-- // -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import _ from 'lodash';
import Tips from '@/components/modules/Tips.vue';
import transformMixin from '@/utils/transformMixin';

export default {
  components: {
    draggable,
    Tips,
  },
  mixins: [transformMixin],
  data() {
    return {
      marketIdsEnabled: [],
      allowedMainTvNetworkMoves: process.env.ALLOWED_MAIN_TV_NETWORKS_SETTINGS_PANEL_MOVE,
      customProgramsMarketIdsEnabled: [],
      tvNetworkIdsEnabled: [],
      defaultValue: '',
      simulcastValue: '',
      performanceHistory: '',
      isShowSampling: false,
      isShowCompetitor: false,
      usePrograms: false,
      useSchedule: false,
      isScheduleDisabled: false,
      isMarketsWeightVisible: false,

    };
  },
  computed: {
    ...mapState(['system', 'settings', 'market']),
    ...mapGetters(['allTvNetworksId', 'tvNetworks']),
    ...mapGetters({ filteredMarkets: 'markets' }),
    tvNetworksAvailable() {
      return _.union(...this.marketsEnabled
        .map(({ tvNetworks }) => tvNetworks.map(({ id }) => id)));
    },
    tvNetworksDisabled() {
      const disabled = [
        ...this.tvNetworksAvailable.filter(id => (this.tvNetworksEnabled.indexOf(id) < 0)),
      ];
      return this.sortTvNetwork(disabled);
    },

    tvNetworksEnabled() {
      return [...this.tvNetworkIdsEnabled.filter(
        tvNetworkId => this.tvNetworksAvailable.indexOf(tvNetworkId) >= 0,
      )];
    },
    marketsDisabled() {
      return [...this.market.markets.filter(
        market => this.marketIdsEnabled.indexOf(market.id) < 0,
      )];
    },
    marketsEnabled() {
      return [...this.marketIdsEnabled.map(
        marketId => this.market.markets.find(market => market.id === marketId),
      ).filter(market => market instanceof Object)];
    },
    customProgramsMarketsDisabled() {

      let marketsCustom = this.market.markets.map(object => ({ ...object }));
        marketsCustom = marketsCustom.filter(item => item.exhibitionName !== 'FLO');
        marketsCustom = marketsCustom.filter(item => item.exhibitionName !== 'POA');
        marketsCustom = marketsCustom.filter(item => item.exhibitionName !== 'MAN');
        marketsCustom = marketsCustom.filter(item => item.id !== 'RPT');
        marketsCustom = marketsCustom.filter(item => item.id !== 'RPP');
        marketsCustom = marketsCustom.filter(item => item.id !== 'FOT');
        marketsCustom = marketsCustom.filter(item => item.id !== 'RB');
        marketsCustom = marketsCustom.filter(item => item.id !== 'MAC');
        marketsCustom = marketsCustom.filter(item => item.id !== 'PVE');
        marketsCustom = marketsCustom.filter(item => item.id !== 'BVI');
        marketsCustom = marketsCustom.filter(item => item.id !== 'CAM');
        marketsCustom = marketsCustom.filter(item => item.id !== 'SOR');
        marketsCustom = marketsCustom.filter(item => item.id !== 'SJP');
        marketsCustom = marketsCustom.filter(item => item.id !== 'BAU');
        marketsCustom = marketsCustom.filter(item => item.id !== 'ITA');
        marketsCustom = marketsCustom.filter(item => item.id !== 'CAB');
        marketsCustom = marketsCustom.filter(item => item.id !== 'SLU');
        marketsCustom = marketsCustom.filter(item => item.id !== 'PNT');
        marketsCustom = marketsCustom.filter(item => item.id !== 'TBA');
        marketsCustom = marketsCustom.filter(item => item.id !== 'TBP');

      return [...marketsCustom.filter(
        market => this.customProgramsMarketIdsEnabled.indexOf(market.id) < 0,
      )];
    },
    customProgramsMarketsEnabled() {

      let marketsCustom = this.market.markets.map(object => ({ ...object }));

        marketsCustom = marketsCustom.filter(item => item.exhibitionName !== 'FLO');
        marketsCustom = marketsCustom.filter(item => item.exhibitionName !== 'POA');
        marketsCustom = marketsCustom.filter(item => item.exhibitionName !== 'MAN');
        marketsCustom = marketsCustom.filter(item => item.id !== 'RPT');
        marketsCustom = marketsCustom.filter(item => item.id !== 'RPP');
        marketsCustom = marketsCustom.filter(item => item.id !== 'FOT');
        marketsCustom = marketsCustom.filter(item => item.id !== 'RB');
        marketsCustom = marketsCustom.filter(item => item.id !== 'MAC');
        marketsCustom = marketsCustom.filter(item => item.id !== 'PVE');
        marketsCustom = marketsCustom.filter(item => item.id !== 'BVI');
        marketsCustom = marketsCustom.filter(item => item.id !== 'CAM');
        marketsCustom = marketsCustom.filter(item => item.id !== 'SOR');
        marketsCustom = marketsCustom.filter(item => item.id !== 'SJP');
        marketsCustom = marketsCustom.filter(item => item.id !== 'BAU');
        marketsCustom = marketsCustom.filter(item => item.id !== 'ITA');
        marketsCustom = marketsCustom.filter(item => item.id !== 'CAB');
        marketsCustom = marketsCustom.filter(item => item.id !== 'SLU');
        marketsCustom = marketsCustom.filter(item => item.id !== 'PNT');
        marketsCustom = marketsCustom.filter(item => item.id !== 'TBA');
        marketsCustom = marketsCustom.filter(item => item.id !== 'TBP');

      return [...this.customProgramsMarketIdsEnabled.map(
        marketId => marketsCustom.find(market => market.id === marketId),
      ).filter(market => market instanceof Object)];
    },
    desktopUserPreferencesCss() {
      const { isUserPreferencesOpen } = this.system;
      return {
        in: isUserPreferencesOpen,
        out: isUserPreferencesOpen === false,
      };
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'system.isUserPreferencesOpen': function (value) {
      if (value) {
        this.defineEnableBtns();
      }
    },
  },
  mounted() {
    this.defineEnableBtns();
  },
  methods: {
    changeShowSampling() {
      this.isShowSampling = !this.isShowSampling;
    },
    handleMove(evt) {
      const { element: tvNetwork, futureIndex, index } = evt.draggedContext;
      if ((!this.allowedMainTvNetworkMoves.includes(tvNetwork) && futureIndex === 0) || (index === 0)) {
        return false;
      }
      return true;
    },
    openTips() {
      this.$refs.tips.open();
    },
    closeUserPreferences() {
      this.$store.dispatch('closeUserPreferences');
      this.defineEnableBtns();
    },
    applyPreferences() {
      this.marketIdsEnabled = this.marketsEnabled.map(({ id }) => id);
      this.$store.dispatch('selectMarketIdOrder', this.marketIdsEnabled);

      this.customProgramsMarketIdsEnabled = this.customProgramsMarketsEnabled.map(({ id }) => id);
      this.$store.dispatch('setCustomProgramsMarketsEnabled', this.customProgramsMarketIdsEnabled);

      const hasEnabledIndividualsMarkets = this.marketsEnabled.some(({ id }) => ['TBA', 'TBP'].includes(id));

      if (!hasEnabledIndividualsMarkets && this.settings.isIndividualsView) {
        this.$store.dispatch('selectIsIndividualsView', false);
      }

      // Select the first market
      const marketTemp = this.settings.market.id;

      let defaultMarket = this.filteredMarkets.find(
        item => item.id === marketTemp,
      );

      if (defaultMarket === undefined) {
        defaultMarket = this.filteredMarkets[0];
        this.$store.dispatch('selectMarket', defaultMarket);
      }

      // If there is only one market we need select market view
      if (this.marketIdsEnabled.length === 1) {
        this.$store.dispatch('selectIsMarketView', true);
      }

      this.$store.dispatch('selectTvNetworkIdOrder', this.tvNetworksEnabled);
      this.$store.dispatch('selectDefaultValue', this.defaultValue);
      this.$store.dispatch('selectSimiulcastValue', this.simulcastValue);
      this.$store.dispatch('selectPerformanceHistory', this.performanceHistory);
      this.$store.dispatch('selectIsShowSampling', this.isShowSampling);
      this.$store.dispatch('selectIsShowCompetitor', this.isShowCompetitor);
      this.$store.dispatch('selectUsePrograms', this.usePrograms);
      this.$store.dispatch('selectUseSchedule', this.useSchedule);
      this.$store.dispatch('selectIsMarketsWeightVisible', this.isMarketsWeightVisible);
      const networkExist = this.tvNetworks.find(({ id }) => (id === this.settings.tvNetworkId));
      if (!networkExist) this.$store.dispatch('selectTvNetworkId', this.tvNetworks[0].id);

      this.closeUserPreferences();
    },
    defineEnableBtns() {
      this.marketIdsEnabled = [...this.settings.marketIdOrder];
      this.customProgramsMarketIdsEnabled = [...this.settings.customProgramsMarkets];
      this.tvNetworkIdsEnabled = [...this.settings.tvNetworkIdOrder];
      this.defaultValue = this.settings.defaultValue;
      this.simulcastValue = this.settings.simulcastValue;
      this.performanceHistory = this.settings.performanceHistory;
      this.isShowSampling = this.settings.isShowSampling;
      this.isShowCompetitor = this.settings.isShowCompetitor;
      this.usePrograms = this.settings.usePrograms;
      this.changeScheduleBtnState();
      this.useSchedule = this.settings.useSchedule;
      this.isMarketsWeightVisible = this.settings.isMarketsWeightVisible;
    },
    changeMarketStatus(marketId) {
      const index = this.marketIdsEnabled.indexOf(marketId);
      const mainTvNetwork = this.tvNetworkIdsEnabled[0];
      if (index > -1) {
        if (this.marketsEnabled.length <= 1) {
          // show error message
        } else {
          // remove
          this.marketIdsEnabled.splice(index, 1);
        }
      } else {
        const { tvNetworks } = this.market.markets.find(({ id }) => id === marketId);
        if (!tvNetworks.find(({ id }) => id === mainTvNetwork)) {
          tvNetworks.forEach(({ id }) => {
            if (this.tvNetworkIdsEnabled.indexOf(id) === -1) this.tvNetworkIdsEnabled.push(id);
          });
          this.tvNetworkIdsEnabled = this.sortTvNetwork(this.tvNetworkIdsEnabled);
        }
        // append
        this.marketIdsEnabled.push(marketId);
      }
    },
    changeCustomProgramsMarketStatus(marketId) {
      const index = this.customProgramsMarketIdsEnabled.indexOf(marketId);
      if (index > -1) {
        if (this.customProgramsMarketIdsEnabled.length <= 1) {
          // show error message
        } else {
          // remove
          this.customProgramsMarketIdsEnabled.splice(index, 1);
        }
      } else {
        this.customProgramsMarketIdsEnabled.push(marketId);
      }
    },
    changeTvNetworkStatus(id) {
      const mainTvNetwork = this.tvNetworksEnabled[0];
      if (id === 'GLOBO') return;
      if (id === mainTvNetwork) return;
      const index = this.tvNetworkIdsEnabled.indexOf(id);
      if (index > -1) {
        if (this.tvNetworksEnabled.length <= 5) {
          // show error message
        } else {
          // remove
          this.tvNetworkIdsEnabled.splice(index, 1);
        }
      } else {
        // append
        this.tvNetworkIdsEnabled.push(id);
        this.tvNetworkIdsEnabled = this.sortTvNetwork(this.tvNetworkIdsEnabled);
      }
    },
    sortTvNetwork(tvNetwork) {
      // put TL and TLE as the last elements of array
      const clone = [...tvNetwork];
      ['TLE', 'TL'].forEach((id) => {
        const index = clone.indexOf(id);
        if (index > -1) {
          clone.splice(index, 1);
          clone.push(id);
        }
      });
      return clone;
    },
    changeDefaultValue(defaultValue) {
      this.defaultValue = defaultValue;
    },

    changeSimulcastValue(simulcastValue) {
      this.simulcastValue = simulcastValue;
    },
    changePerformanceHistory(performanceHistory) {
      this.performanceHistory = performanceHistory;
    },
    changeScheduleBtnState() {
      if (this.isShowCompetitor || this.usePrograms) {
        this.isScheduleDisabled = false;
      } else {
        this.isScheduleDisabled = true;
        this.useSchedule = false;
      }
    },
    changeShowCompetitor() {
      this.isShowCompetitor = !this.isShowCompetitor;
      this.changeScheduleBtnState();
    },
    changeUsePrograms() {
      this.usePrograms = !this.usePrograms;
      this.changeScheduleBtnState();
    },
    changeUseSchedule() {
      this.useSchedule = !this.useSchedule;
    },
    changeMarketsWeightStatus() {
      this.isMarketsWeightVisible = !this.isMarketsWeightVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

  // Filtros
  .filtros {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: none;

    font-family: $secondary-typo;
    font-size: 1em;
    font-weight: 400;
    pointer-events: none;

    .overlay {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      background-color: rgba($color-gray-darker, 0.8);
      opacity: 0;

      .content {
        position: relative;
        width: 500px;
        height: 100%;
        background-color: $color-gray-back;
        box-shadow: -24px 0 80px rgba($color-gray-darker, 0.4);
        overflow-y: auto;
        opacity: 0;

        .header {
          position: fixed;
          top: 0;
          left: 0;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          z-index: 1;
          width: 100%;
          height: 80px;
          padding: 0 24px;
          background-color: $color-gray-lighter;
          //border-bottom: solid 1px rgba($color-gray-light, 0.4);
          box-shadow: 0 8px 24px rgba($color-gray-darker, 0.08);

          // Título
          h1 {
            display: flex;
            align-items: center;
            height: 100%;
            margin: 0;
            padding: 0;

            font-family: $primary-typo;
            font-size: 0.8em;
            font-weight: normal;
            color: $color-gray-darker;
            text-transform: uppercase;
            letter-spacing: 0.08em;
          }

          i {
            font-size: 1.2em;
            margin: 0 12px 0 0;
            color: rgba($color-gray-dark, 0.4);
          }

          .buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
            margin: 0 0 0 auto;
            padding: 0;

            .btn {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 40px;
              box-shadow: none;

              i {
                margin: 0;
                padding: 0 4px;
                color: $color-primary;
              }

              &:hover {
                i {
                  color: $color-gray-lighter;
                }
              }
            }
          }
        }
        // -------

        // Middle
        .middle {
          display: block;
          width: 100%;
          height: 100vh;
          padding: 80px 24px 48px 24px;
          overflow-y: auto;

          .description {
            width: 100%;
            margin: 0 0 16px 0;
            font-size: 0.9em;
            font-style: italic;
            line-height: 1.2em;
            color: $color-gray-dark;

            &::after {
              content: "";
              display: block;
              width: 24px;
              height: 4px;
              margin: 16px 0 0 0;
              background-color: rgba($color-gray-light, 0.4);
            }
          }
          // --------

          .options {
            display: flex;
            flex-wrap: wrap;

            width: 100%;
            margin: 0;
            padding: 0 0 8px 0;

            // -------

            // Switch
            .item {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              width: 100%;
              padding: 16px 8px 16px 2px;

              font-size: 0.8em;
              font-weight: 500;
              text-transform: uppercase;
              color: $color-gray-main;
              border-bottom: solid 1px rgba($color-gray-light, 0.48);

              &.disabled {
                pointer-events: none;
                opacity: 0.4;
              }

              &:first-child {
                padding: 0 0 16px 0;
              }

              &:last-child {
                border: none;
              }

              i {
                margin: 0 12px 0 0;
                font-size: 1.2em;
                color: $color-gray-light;
              }

              .stacked-icons {
                position: relative;
                margin: 0 12px 0 0;

                i {
                  position: absolute;
                  top: 3px;
                  left: 3px;
                  font-size: .7em;
                  margin: 0;

                  &:first-child {
                    position: static;
                    font-size: 1.2em;
                  }
                }
              }

              .switch {
                position: relative;
                display: inline-block;
                margin-left: auto;
                width: 48px;
                height: 28px;

                input {
                  opacity: 0;
                  width: 0;
                  height: 0;
                }

                .slider {
                  position: absolute;
                  cursor: pointer;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background-color: $color-gray-light;
                  transition: .4s;

                    &::before {
                      position: absolute;
                      content: "";
                      height: 22px;
                      width: 22px;
                      left: 3px;
                      bottom: 3px;
                      border-radius: 100%;
                      background-color: $color-gray-lighter;
                      box-shadow: 0 4px 16px rgba($color-gray-darker, 0.24);
                      transition: .4s;
                    }

                    /* Rounded sliders */
                    &.round {
                      border-radius: 34px;
                    }
                }

                input:checked + .slider {
                  background-color: $color-secondary;
                }

                input:checked + .slider::before {
                  transform: translateX(20px);
                }
              }
            }

            .disclaimer {
              font-size: .85em;
              color: $color-gray-lighter;
              margin-bottom: 0;
              background-image: linear-gradient(-224deg, $color-secondary 0%, $color-primary 100%);
              padding: 10px;
              border-radius: 4px;
              font-weight: bold;
            }

            // Título
            h1 {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              align-items: center;
              width: 100%;
              padding: 16px 0 0 0;
              font-family: $primary-typo;
              font-size: 0.8em;
              font-weight: 500;
              text-transform: uppercase;
              color: $color-gray-darker;

              i {
                padding: 8px 8px 8px 0;
                margin: 0 4px 0 0;
                font-size: 1.4em;
                color: rgba($color-gray-dark, 0.4);
              }

              .btn {
                margin: 0 0 0 auto;

                &.help {

                  i {
                    background: transparent;
                    padding: 0;
                    margin: 0;
                  }
                }
              }
            }

            // Tags
            .area {
              display: block;
              width: 100%;
              height: auto;
              background: $color-gray-lighter;
              border-radius: 4px;
              box-sizing: border-box;
              padding: 8px;
              margin: 8px 0;

              // Btns / Tags
              .btn {
                display: inline-flex;
                flex-wrap: wrap;
                align-items: center;
                width: 72px;
                height: 32px;
                margin: 2px 4px 2px 0;
                border: none;
                border-radius: 4px;
                font-size: 0.72em;
                font-weight: 500;
                letter-spacing: 0;
                opacity: 1;
                transition: opacity 0.4s ease-in-out;


                // Icone
                i {
                  font-size: 1.4em;
                  margin-left: auto;
                }

                // Tag-item
                &.tag_item {
                  background-color: $color-primary-light;
                  color: $color-gray-lighter;
                  box-shadow: 0 4px 16px rgba($color-gray-darker, 0.16);
                  cursor: pointer;
                  transition: background-color 0.8s ease-in-out;
                }
                // -----------

                // Tratamento de tag especial
                &.especial {
                  background-color: $color-gray-dark;

                  // Ripple Effect
                  &.ripple {
                    transition: color 0.8s ease-in-out, background 0.56s ease-in-out, box-shadow 0.8s ease-in-out, opacity 0.8s ease-in-out;

                    &:hover {
                      background: $color-gray-darker radial-gradient(circle, transparent 1%, $color-gray-darker 1%) center/15000%;
                      color: $color-gray-lighter;

                      & i {
                        color: $color-gray-lighter;
                      }
                    }

                    &:active {
                      background-color: rgba($color-gray-main, 1);
                      background-size: 100%;
                      transition: background 0s;
                    }
                  }
                }

                // Tratamento de tag especial
                &.disabled {
                  background-color: $color-gray-lighter;
                  color: $color-gray-main;
                  pointer-events: auto;
                }

                // --------------
              }

              &.draggableList {
                .ghost-background{
                  background: $color-gray-lighter;
                  border: dashed 2px $color-primary-light;
                  color: $color-primary-light;
                  opacity: 0.48;
                }
              }
            }

            // Ajustes para o espaçamento dos marcos históricos
            &:last-child {
              margin-bottom: 48px;
            }
            // ---------------
          }

          .divisor {
            width: 100%;
            height: 1px;
            // border-bottom: solid 2px rgba($color-gray-lighter, 1);
            margin: 0 0 0 0;
          }
        }
      }
    }

    // Entrada
    &.in {
      pointer-events: visible;
      display: block;

      .overlay {
        animation: fadeIn 1s ease-in-out forwards;

        .content {
          animation-delay: 0.5s !important;
          animation: fadeInRight 0.8s ease-in-out forwards;
        }
      }
    }

    // Saída
    &.out {
      pointer-events: none;
      display: block;

      .overlay {
        opacity: 1;
        animation-delay: 0.4s !important;
        animation: fadeOut 1s ease-in-out forwards;

        .content {
          opacity: 1;
          animation: fadeOutRight 0.8s ease-in-out forwards;
        }
      }
    }
    // -------------
  }

// SMARTPHONES
@media (max-width: 600px) {
    // Filtros
    .filtros {
      &.in,
      &.out {
        display: none;
      }
    }
}
</style>
