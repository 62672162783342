<template>
  <div class="navigation">

    <ToggleTargetButton
      v-if="!!individualsMarkets.length"
      :name-left="'DOMICILIAR (%)'"
      :name-right="'INDIVIDUAL (#)'"
      :is-toggle-left="!settings.isIndividualsView"
      :disabled="activateSimulcastView || settings.isDashView || settings.isSprintView || settings.isRailView"
      style="margin-right: 16px"
      @clicked="changeIsIndividualsView"
    />

    <ToogleButton
      :name-left="'Minutos'"
      :name-right="'Programas'"
      :is-toogle-left="settings.isMinuteView || activateSimulcastView"
      :disabled="activateSimulcastView || settings.isDashView || settings.isSprintView || settings.isRailView"
      @clicked="changeIsMinuteView"
    />


    <ViewNavigation :disabled="activateSimulcastView || settings.isIndividualsView" />
    
    <!-- Well Lima Thera - 28-05-2020 -->
    <!-- Ajustado o is-toogle-left para visão de simulcast -->
    <ToogleButton
      :icon-left="'pin_drop'"
      :icon-right="'tv'"
      :tip-left="'Praças'"
      :tip-right="'Emissoras'"
      :is-toogle-left="settings.isMarketView"
      :disabled="settings.marketIdOrder.length === 1
        || !isInHomePage
        || activateSimulcastView
        || settings.isSprintView
        || settings.isRailView
        || settings.isIndividualsView"
      @clicked="changeIsMarketView"
    />

    <SingleToogleButton
      v-if="settings.hasSimuCast"
      :icon="'phonelink'"
      :tip="'Simulcast'"
      :activated="activateSimulcastView"
      @clicked="changeIsSimuCastView"
    />

    <div class="divisor" />

    <SelectDate ref="SelectDate" />

    <div class="divisor" />

    <div class="buttons">
      <button type="button" class="btn btn_header ripple" @click="openUserPreferences()">
        <i class="icone material-icons tool" data-tip="Preferências">
          tune
        </i>
      </button>

      <button
        type="button"
        class="btn btn_header ripple"
        @click="downloadCsv()"
        :disabled="!isButtonCsvEnabled
          || !settings.isButtonDownloadActivate
          || settings.isSprintView
          || settings.isRailView
          || settings.isIndividualsView"
      >
        <i class="icone material-icons tool" data-tip="Download">
          file_download
        </i>
      </button>

      <button
        :disabled="settings.isSprintView || settings.isRailView || settings.isIndividualsView"
        type="button"
        class="btn btn_header ripple"
        @click="openChart()"
      >
        <i class="icone material-icons tool" data-tip="Chart">
          show_chart
        </i>
      </button>

      <button type="button" class="btn btn_header ripple" @click="fullscreen()">
        <i class="icone material-icons tool" data-tip="Tela cheia">
          fullscreen
        </i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ToogleButton from '@/components/modules/ToogleButton.vue';
import SingleToogleButton from '@/components/modules/SingleToogleButton.vue';
import ViewNavigation from '@/components/modules/ViewNavigation.vue';
import SelectDate from '../SelectDate.vue';
import ToggleTargetButton from "../../../modules/ToggleTargetButton.vue";

export default {
  components: {
    ToggleTargetButton,
    ToogleButton,
    SelectDate,
    SingleToogleButton,
    ViewNavigation,
  },
  computed: {
    ...mapState(['user', 'settings', 'market']),
    ...mapGetters(['currentUser', 'individualsMarkets']),
    ...mapGetters({ filteredMarkets: 'markets' }),
    isButtonCsvEnabled() {
      return this.isInHomePage;
    },
    
    isInHomePage() {
      return this.$route.name === 'Home';
    },
    
    isDashboardEnabled() {
      const { markets } = this.market;
      return markets.find(market => market.tvNetworks.find(tvNetwork => tvNetwork.id === 'GLOBO'));
    },

    activateSimulcastView() {
      if (this.settings.isSimuCastView && !this.settings.hasSimuCast) {
        this.changeIsMinuteView();
        return false;
      }
      return !!(this.settings.hasSimuCast && this.settings.isSimuCastView);
    },

    activateSprintView() {
      if (this.settings.isSprintView) {
        return true;
      }
      return !!this.settings.isSprintView;
    },
  },
  mounted() {
    window.addEventListener('fullscreenchange', this.fullscreenChange);
  },
  methods: {
    switchHomeAndDashboardPages() {
      this.$store.dispatch('selectIsMinuteView', false);
      const pageName = this.isInHomePage ? 'Dashboards' : 'Home';
      this.goToPage(pageName);
    },
    
    downloadCsv() {
      this.$modal.show('export');
    },
    
    goToPage(name) {
      if (this.$route.name !== name) {
        this.$router.push({ name });
      }
    },
    
    changeIsMinuteView() {
      this.$store.dispatch('selectIsMinuteView', !this.settings.isMinuteView);
      this.goToPage('Home');
    },

    changeIsIndividualsView() {
      const value = !this.settings.isIndividualsView;
      this.$store.dispatch('selectIsIndividualsView', value);
      this.$store.dispatch('selectMarket', this.filteredMarkets[0]);
      this.$store.dispatch('selectIsMarketView', true);
      if (value) {
        this.$store.dispatch('selectDefaultValue', 'audience');
      }
      this.goToPage('Home');
    },
    
    changeIsSimuCastView() {
      this.$store.dispatch('selectIsMarketView', this.settings.isSimuCastView);
      this.$store.dispatch('selectIsInSimuCastView', !this.settings.isSimuCastView);
      this.goToPage('Home');
    },
    
    changeIsMarketView() {
      this.$store.dispatch('selectIsMarketView', !this.settings.isMarketView);
    },
    
    changeIsKpi() {
      this.$store.dispatch('selectIsKpi', !this.settings.isKpi);
    },
    
    openUserPreferences() {
      this.$store.dispatch('openUserPreferences');
    },
    
    openChart() {
      if (this.settings.isSimuCastView) {
        this.$modal.show('chart-simulcast');
      } else {
        this.$modal.show('chart');
      }
    },
    
    fullscreen() {
      const elem = document.getElementById('scrollArea');
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen();
      }
    },
    
    fullscreenChange() {
      // eslint-disable-next-line max-len
      const isFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
      if (isFullScreen) {
        const elem = document.getElementById('scrollArea');
        if (elem) {
          elem.scrollLeft = 0;
          elem.scrollTop = this.settings.isDescending ? 0 : elem.scrollHeight;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variables.scss';
</style>
