<template>
  <div class="toggle" :class="{ disabled }">
    <button
      type="button"
      class="btn ripple toggler"
      :class="{ enabled: isToggleLeft, disabled: disabledLeft }"
      @click="changeToggle('left')"
    >
      {{ nameLeft }}
    </button>
    <button
      type="button"
      class="btn ripple toggler"
      :class="{ enabled: !isToggleLeft, disabled: disabledRight }"
      @click="changeToggle('right')"
    >
      {{ nameRight }}
    </button>

    <div class="selected" :class="{ left: isToggleLeft, right: !isToggleLeft }" />
  </div>
</template>

<script>
export default {
  props: {
    nameLeft: {
      type: String,
      default: null,
    },
    nameRight: {
      type: String,
      default: null,
    },
    isToggleLeft: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledLeft: {
      type: Boolean,
      default: false,
    },
    disabledRight: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    changeToggle(position) {
      const currentPosition = this.isToggleLeft ? 'left' : 'right';
      if (currentPosition !== position) this.$emit('clicked');
    },
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';

// Toggles
.toggle {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  height: 40px;
  border-radius: 4px;
  background-color: $color-gray-lighter;
  box-shadow: 0 2px 8px rgba($color-gray-darker, 0.08);

  .selected {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 143.63px;
    height: 100%;
    background-image: linear-gradient(-224deg, $color-secondary 0%, $color-primary 100%);
    transition: transform 0.4s ease-in-out, border-radius 0.8s ease-in-out, width 0.8s ease-in-out;
    animation-delay: 4s;

    &.left {
      transform: translate(0, 0);
      width: 143.63px;
      border-radius: 4px 0 0 4px;
    }

    &.right {
      transform: translate(143.63px, 0);
      width: 140.94px;
      border-radius: 0 4px 4px 0;
    }
  }

  .toggler {
    position: relative;
    z-index: 4;
    height: 100%;
    padding: 0 16px;
    border: none;
    border-radius: 4px;
    font-family: $primary-typo;
    font-size: 0.64em;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.16em;
    color: $color-gray-main;
    cursor: pointer;
    background-color: transparent;
    animation: fadeIn 0.8s ease-in-out;

    // Ripple Effect
    &.ripple {
      background-position: center;
      transition: color 0.4s ease-in-out, background 0.4s ease-in-out, box-shadow 0.4s ease-in-out, opacity 0.4s ease-in-out;

      &:hover {
        background: $color-gray-lighter radial-gradient(circle, transparent 1%, $color-gray-lighter 1%) center/15000%;
        box-shadow: 0 4px 16px rgba($color-gray-darker, 0.08);
        color: $color-secondary;
      }

      &:active {
        background-color: rgba($color-gray-light, 0.4);
        background-size: 100%;
        transition: background 0s;
      }
    }

    &.enabled {
      // Ripple Effect
      &.ripple {
        background-position: center;
        color: $color-gray-lighter;
        transition: color 0.4s ease-in-out, background 0.4s ease-in-out, box-shadow 0.4s ease-in-out, opacity 0.4s ease-in-out;

        &:hover {
          background: rgba($color-gray-lighter, 0.16) radial-gradient(circle, transparent 1%, rgba($color-gray-lighter, 0.16) 1%) center/15000%;
          box-shadow: 0 4px 16px rgba($color-gray-darker, 0.08);
          color: $color-gray-lighter;
        }

        &:active {
          background-color: rgba($color-gray-lighter, 0.4);
          background-size: 100%;
          transition: background 0s;
        }
      }
    }

    // Left
    &.left {
      color: $color-gray-lighter;
    }
  }

  // DISABLED
  &.disabled, 
  .disabled {
    pointer-events: none;
    background-color: $color-gray-lighter;
    opacity: 0.4;
  }
  // ---------------------------

}
</style>
