<template>
  <div id="Home" class="home">
    <!-- Top Mobile -->
    <div class="top combo">
      <div class="especial">
        <!-- <button type="button" class="btn btn_header ripple" @click="goToPage('Settings')">
          <i class="icone material-icons">
            settings
          </i>
        </button>-->

        <button type="button" class="btn btn_header ripple" @click="openUserPreferences()">
          <i class="icone material-icons">tune</i>
        </button>

        <!-- <button type="button" class="btn btn_header ripple">
          <i class="icone material-icons">
            assistant
          </i>
        </button>-->
      </div>

      <!-- // -->
      <div class="audience">
        <div class="value" :class="lastProgramClass">{{ lastProgramAverage }}</div>
      </div>
    </div>

    <TagSelect v-if="!settings.isSimuCastView " />

    <!--<RealtimeTables />-->
    <div v-if="innerErrorOptions" class="table_container error">
      <ErrorState
        :insides="innerErrorOptions.insides"
        :title="innerErrorOptions.title"
        :subtitle="innerErrorOptions.subtitle"
        :clicked="innerErrorOptions.clicked"
        :buttons="innerErrorOptions.buttons"
        :fullscreen="false"
      />
    </div>

    <template v-else-if="settings.isMinuteView">
      <MinuteDetails v-if="!settings.isIndividualsView" />
      <EmadRealtimeTables />
    </template>
    
    <template v-else-if="settings.isSimuCastView">
      <!-- <SimulcastDetails /> -->
      <SimulcastTable />
    </template>

    <template v-else-if="settings.isSprintView">
      <SprintTable />
    
    </template>

    <template v-else-if="settings.isRailView">
      <RailTable/>
    </template>

    <template v-else>
      <ProgramDetails v-if="!settings.isIndividualsView" />
      <RealtimeProgramsTables />
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import device from "@/utils/device";
import ErrorState from "@/components/system/ErrorState.vue";
import EmadRealtimeTables from "./Home/EmadRealtimeTables.vue";
import RealtimeProgramsTables from "./Home/RealtimeProgramsTables.vue";
import SimulcastDetails from "./Home/SimulcastDetails.vue";
import SimulcastTable from "./Home/SimulcastTable.vue";
import TagSelect from "./Home/TagSelect.vue";
import MinuteDetails from "./Home/MinuteDetails.vue";
import ProgramDetails from "./Home/ProgramDetails.vue";
import SprintTable from "../../../v2/Views/Home/SprintView/SprintView.vue";
import RailTable from "../../../v2/Views/Home/RailView/RailView.vue";

export default {
  components: {
    EmadRealtimeTables,
    RealtimeProgramsTables,
    SimulcastDetails,
    SimulcastTable,
    SprintTable,
    RailTable,
    // TimeBar,
    // Header,
    TagSelect,
    MinuteDetails,
    ProgramDetails,
    ErrorState
  },
  data() {
    return {
      isApp: device.isApp()
    };
  },
  computed: {
    ...mapState(["settings", "globoSchedule", "market", "system"]),
    ...mapGetters(["innerErrorOptions", "scheduleTvNetworkId"]),
    lastProgramAverage() {
      const marketAveragesList = this.globoSchedule.lastProgram.averages || {};
      const tvNetworkAveragesList = marketAveragesList[this.settings.market.id] || {};
      const lastTvNetworkProgramAverage = tvNetworkAveragesList[this.scheduleTvNetworkId];

      if (!lastTvNetworkProgramAverage) {
        return "-";
      }

      if (this.settings.isIndividualsView) {
        return Intl.NumberFormat('pt-BR').format(lastTvNetworkProgramAverage.audience.toFixed(0));
      }

      return lastTvNetworkProgramAverage.audience.toFixed(2);
    },
    lastProgramClass() {
      if (!this.globoSchedule.lastProgram.tvNetworkWinners) return "";
      return this.indicadoresAudience(
        this.scheduleTvNetworkId,
        this.globoSchedule.lastProgram.tvNetworkWinners[this.settings.market.id]
      );
    }
  },
  beforeCreate() {
  },
  mounted() {
    // Caso esteja na visão mobile, a home sempre direciona a aplicação para a visão de tabelas
    // já que as outras visões não estão disponíveis para mobile
    if (this.system.isMobileView) {
      this.$store.dispatch("selectDetailsView", "table");
    }

    if (this.isApp) window.screen.orientation.unlock();
    this.unwatch = this.$store.watch(
      state => state.settings,
      () => {
        this.$store.dispatch("hideError");
      },
      { deep: true }
    );
  },
  beforeDestroy() {
    if (this.isApp) window.screen.orientation.lock("portrait");
    this.unwatch();
    this.$store.dispatch("hideInnerError");
  },
  methods: {
    openUserPreferences() {
      this.$store.dispatch("openUserPreferences");
    },
    logout() {
      this.$store.dispatch("logout");
    },
    goToPage(name) {
      this.$router.push({ name });
    },
    indicadoresAudience(tvNetworkId, tvNetworkWinners) {
      let className = "";
      if (tvNetworkWinners) {
        if (tvNetworkWinners.includes(tvNetworkId)) {
          className = tvNetworkWinners.length === 1 ? "won" : "drew";
        } else if (tvNetworkId === this.scheduleTvNetworkId) {
          className = "lost";
        }
      }

      return className;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/audience.scss";
@import "@/assets/scss/audience-responsive.scss";
@import "@/assets/scss/audience-fullscreen.scss";
@import "@/assets/scss/tags.scss";

.home {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  padding: 0;
  height: 100vh;
  margin: 0;

  // Topo
  .top {
    display: none;
  }
}

// SMARTPHONES
@media (max-width: 600px) {
  .home {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    height: 100%;
    margin: 0 auto;
    opacity: 0;
    animation: fadeIn 2s ease-in-out forwards;

    // Filtros
    .filtros {
      display: none;
    }

    .box {
      width: 100%;
      padding: 0;
    }

    .brand {
      display: none;
    }

    // --------------------

    // Definições para o topo
    .top {
      position: fixed;
      top: env(safe-area-inset-top, 0);
      left: 0;
      z-index: 2;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: 96px;
      margin: 0;
      padding: 40px 0 0 0;
      background: $color-gray-dark;
      box-shadow: 0 2px 16px rgba($color-gray-darker, 0.08);

      // Definições para a área especial do topo
      .especial {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        box-sizing: border-box;
        padding: 8px;
        width: 56%;
        height: 100%;

        .btn {
          margin: 0 4px;

          i {
            margin: 0;
            top: -2px;
            left: -2px;
            font-size: 1.8em;
          }

          // Ripple Effect
          &.ripple {
            background-position: center;
            background-color: $color-gray-dark;
            transition: background 0.4s ease-in-out, opacity 0.8s ease-in-out,
              box-shadow 0.4s ease-in-out;

            & i {
              color: $color-gray-lighter;
              transition: color 0.4s ease-in-out;
            }

            &:hover {
              background: $color-gray-dark
                radial-gradient(circle, transparent 1%, $color-gray-dark 1%)
                center/15000%;
              color: $color-gray-lighter;
              box-shadow: 0 4px 8px rgba($color-gray-darker, 0.08);
            }

            &:active {
              background-color: rgba($color-gray-dark, 0.8);
              background-size: 100%;
              transition: background 0s;
            }
          }
        }
      }
      // --------------

      .audience {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;

        box-sizing: border-box;
        padding: 8px 16px;
        margin: 0 0 0 auto;

        width: 40%;
        height: 99%;

        background: repeating-linear-gradient(
          45deg,
          rgba($color-gray-darker, 0.24),
          rgba($color-gray-darker, 0.24) 4px,
          $color-gray-dark 4px,
          $color-gray-dark 10px
        );

        .value {
          font-family: $primary-typo;
          font-size: 1.6em;
          color: $color-gray-light;

          // Status Ganhando
          &.won {
            color: $color-status-won;
          }

          // Status Empatando
          &.drew {
            color: $color-status-drew;
          }

          // Status Perdendo
          &.lost {
            color: $color-status-lost;
          }
        }
      }
    }
  }
}
</style>
