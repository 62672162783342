<template>
  <div class="toggle icons" :class="{ disabled }">
    <button
      type="button"
      class="btn ripple toggler"
      :class="{ enabled: settings.isTableView }"
      @click="changeDetailsView('table')"
    >
      <i class="icone material-icons tool" data-tip="Tabelas">
        reorder
      </i>
    </button>

    <button
      type="button"
      class="btn ripple toggler"
      :class="{ enabled: settings.isDashView, disabled: !isDashboardEnabled }"
      @click="changeDetailsView('dashboards')"
    >
      <i class="icone material-icons tool" data-tip="Dashboards">
        view_carousel
      </i>
    </button>

    <!-- <button
      type="button" class="btn ripple toggler"
      :class="{ enabled: settings.isSprintView }"
      @click="changeDetailsView('sprint')"
    >
      <i class="icone material-icons tool" data-tip="Sprint">
        view_stream
      </i>
    </button> -->

    <button
      type="button" class="btn ripple toggler rail"
      :class="{ enabled: settings.isRailView }"
      @click="changeDetailsView('rail')"
    >
      <i class="icone material-icons tool top-line" data-tip="Curva de Desempenho">
        show_chart
      </i>
      <i class="icone material-icons tool bottom-line" data-tip="Curva de Desempenho">
        show_chart
      </i>
    </button>

    <div
      class="selected"
      :class="{
        'first-item-selected': settings.isTableView,
        'second-item-selected': settings.isDashView,
        'third-item-selected': settings.isRailView,
        // 'third-item-selected': settings.isSprintView,
        // 'fourth-item-selected': settings.isRailView,
      }"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['settings', 'market', 'user']),

    isDashboardEnabled() {
      const { markets } = this.market;
      return markets.find(market => market.tvNetworks.find(tvNetwork => tvNetwork.id === 'GLOBO'));
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    goToPage(name) {
      if (this.$route.name !== name) {
        this.$router.push({ name });
      }
    },

    changeDetailsView(view) {
      this.$store.dispatch('selectIsMinuteView', false);
      this.$store.dispatch('selectDetailsView', view);
      let pageName;
      if (view == 'dashboards') {
        pageName = 'Dashboards';
      } else {
        pageName = 'Home';
      }

      if (process.env.GOOGLE_ANALYTICS_APP_ID && this.$gtag) {
        this.$gtag.event('view_change', {
          view_name: view,
        });
      }

      this.goToPage(pageName);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

// Toggles
.toggle {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  height: 40px;
  border-radius: 4px;
  background-color: $color-gray-lighter;
  box-shadow: 0 2px 8px rgba($color-gray-darker, 0.08);

  .selected {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 108px;
    height: 100%;
    border-radius: 0;
    background-image: linear-gradient(-224deg, $color-secondary 0%, $color-primary 100%);
    transition: transform 0.4s ease-in-out, border-radius 0.8s ease-in-out, width 0.8s ease-in-out;
    animation-delay: 4s;

    &.first-item-selected {
      border-radius: 4px 0 0 4px;
    }

    &.third-item-selected {
      border-radius: 0 4px 4px 0;
    }

    // &.fourth-item-selected {
    //   border-radius: 0 4px 4px 0;
    // }
  }

  .toggler {
    position: relative;
    z-index: 4;
    height: 100%;
    padding: 0 16px;
    border: none;
    border-radius: 4px;
    font-family: $primary-typo;
    font-size: 0.64em;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.16em;
    color: $color-gray-main;
    cursor: pointer;
    background-color: transparent;
    animation: fadeIn 0.8s ease-in-out;

    &.rail {
      .top-line {
        position: absolute;
        top: 0;
      }

      .bottom-line {
        position: absolute;
        top: 5px;
      }
    }

    // Ripple Effect
    &.ripple {
      background-position: center;
      transition: color 0.4s ease-in-out, background 0.4s ease-in-out, box-shadow 0.4s ease-in-out, opacity 0.4s ease-in-out;

      &:hover {
        background: $color-gray-lighter radial-gradient(circle, transparent 1%, $color-gray-lighter 1%) center/15000%;
        box-shadow: 0 4px 16px rgba($color-gray-darker, 0.08);
        color: $color-secondary;
      }

      &:active {
        background-color: rgba($color-gray-light, 0.4);
        background-size: 100%;
        transition: background 0s;
      }
    }

    &.enabled {
      // Ripple Effect
      &.ripple {
        background-position: center;
        color: $color-gray-lighter;
        transition: color 0.4s ease-in-out, background 0.4s ease-in-out, box-shadow 0.4s ease-in-out, opacity 0.4s ease-in-out;

        &:hover {
          background: rgba($color-gray-lighter, 0.16) radial-gradient(circle, transparent 1%, rgba($color-gray-lighter, 0.16) 1%) center/15000%;
          box-shadow: 0 4px 16px rgba($color-gray-darker, 0.08);
          color: $color-gray-lighter;
        }

        &:active {
          background-color: rgba($color-gray-lighter, 0.4);
          background-size: 100%;
          transition: background 0s;
        }
      }
    }

    // Left
    &.first-item-selected {
      color: $color-gray-lighter;
    }

    /*== start of code for tooltips ==*/
    .tool {
      position: relative;

      &::before,
      &::after {
        left: 50%;
        opacity: 0;
        position: absolute;
        z-index: -100;
      }

      &:hover::before,
      &:focus::before,
      &:hover::after,
      &:focus::after {
        animation-delay: 0.4s !important;
        animation: fadeInDown 0.4s ease-in-out forwards;
        z-index: 100;
      }

      // Balão
      &::after {
        content: attr(data-tip);
        background: rgba($color-gray-lighter, 0.56);
        top: 124%;
        padding: 8px;
        box-sizing: border-box;
        margin-left: -54px;
        width: 130px;
        font-family: $secondary-typo;
        font-size: 0.50em;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        color: $color-secondary;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba($color-gray-darker, 0.08);
        transition: all .65s cubic-bezier(.84, -0.18, .31, 1.26) .2s;
        opacity: 0;
        pointer-events: none;
        animation: fadeOutUp 0.4s ease-in-out forwards;
      }

      &:hover::after,
      &:focus::after {
        transition: all .65s cubic-bezier(.84, -0.18, .31, 1.26);
      }
    }
  }

  // ICONS
  &.icons {
    margin-left: 16px;

    .toggler {
      padding: 0;
    }

    .toggler,
    .selected {
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.first-item-selected {
        transform: translate(0, 0);
      }

      &.second-item-selected {
        transform: translate(100%, 0);
      }

      &.third-item-selected {
        transform: translate(200%, 0);
      }

      &.fourth-item-selected {
        transform: translate(300%, 0);
      }

      i {
        font-size: 1.8em;
        padding: 8px 4px;
      }
    }
  }

  // ---------------------------

  // DISABLED
  &.disabled,
  .disabled {
    pointer-events: none;
    background-color: $color-gray-lighter;
    opacity: 0.4;
  }

  // ---------------------------

}
</style>
