<template>
  <!-- Navigation Mobile -->
  <div class="navigation_mobile" :class="navigationCss">
    <div class="overlay">
      <div class="content">
        <div class="topo">
          <button type="button" class="btn ripple" @click="closeUserPreferences()">
            <i class="icone material-icons">keyboard_arrow_left</i>
          </button>
          <div class="title">Filtros / Preferências</div>
        </div>
        <!-- // -->

        <!-- <ToogleButton :name-left="'Visualização'" :name-right="'Períodos'"
                      :is-toogle-left="isVisualizationView" @clicked="changeView"
        />-->

        <!-- Miolo da visualização -->
        <div class="middle">
          <!-- <div class="description">
            Escolha entre as visões de Minutos e Programas,
            além da navegação por Praças ou Emissoras.
          </div>-->

          <div v-if="isInHomePage && individualsMarkets.length" class="options">
            <div class="title">Universo</div>

            <div class="item">
              <button
                  type="button"
                  class="btn ripple"
                  :class="cssBtn(!isIndividualsView)"
                  @click="setIsIndividualsView(false)"
              >
                <i class="icone material-icons">home</i>
              </button>
              <span class="text">Domiciliar (%)</span>
            </div>

            <div class="item">
              <button
                  type="button"
                  class="btn ripple"
                  :class="cssBtn(isIndividualsView)"
                  @click="setIsIndividualsView(true)"
              >
                <i class="icone material-icons">person</i>
              </button>
              <span class="text">Individual (#)</span>
            </div>
          </div>

          <div v-if="isInHomePage" class="options">
            <div class="title">Visão</div>

            <div class="item">
              <button
                type="button"
                class="btn ripple"
                :class="cssBtn(isMinuteView)"
                @click="setIsMinuteView(true)"
              >
                <i class="icone material-icons">access_time</i>
              </button>
              <span class="text">Minutos</span>
            </div>

            <div class="item">
              <button
                type="button"
                class="btn ripple"
                :class="cssBtn(!isMinuteView)"
                @click="setIsMinuteView(false)"
              >
                <i class="icone material-icons">local_play</i>
              </button>
              <span class="text">Programas</span>
            </div>
          </div>
          <!-- // -->

          <div v-if="isInHomePage" class="options">
            <div class="title">Navegação</div>

            <div class="item">
              <button
                type="button"
                class="btn ripple"
                :class="cssBtn(isMarketView)"
                @click="setIsMarketView(true)"
              >
                <i class="icone material-icons">pin_drop</i>
              </button>
              <span class="text">Praças</span>
            </div>

            <div class="item" :class="{ disabled: isIndividualsView }">
              <button
                type="button"
                class="btn ripple"
                :class="cssBtn(!isMarketView)"
                @click="setIsMarketView(false)"
              >
                <i class="icone material-icons">tv</i>
              </button>
              <span class="text">Emissoras</span>
            </div>
          </div>
          <!-- // -->

          <div class="options">
            <div class="title">Dias</div>

            <div v-for="d in marketDays.slice(0).reverse()" :key="d.id" class="item">
              <button
                type="button"
                class="btn day ripple"
                :class="cssBtn(d.id === day)"
                @click="changeDay(d.id)"
              >{{ d.dFormat }}</button>
              <span class="text">
                <div class="week">{{ d.dayName }}</div>
                <div class="month">{{ d.dateFormat }}</div>
              </span>
            </div>
          </div>
          <!-- // -->
        <div class="slider-options">
          <div class="slider-title">Horário</div>
          <div class="clear-btn">
            <button class="btn btn_primary ripple clear-btn" @click="clearRangeTimeOptions()">
              Limpar
            </button>
          </div>
            <vue-slider ref="slider" v-model="rangeTime" :min-range="1" :data="timeData"  style="width:90%;"/>
            <div class="time">
              <input type="time" required :value="rangeTime[0]"  @input="changeStartTimeValue">
              <span class="highlight" />
              <span class="bar" />
              <input type="time"  required :value="rangeTime[1]"  @input="changeEndTimeValue">
              <span class="highlight" />
              <span class="bar" />
            </div>
        </div>
          <div class="options">
            <div class="title">Complementos</div>

            <div class="item_row">
              <div class="item_column">
                <!-- / DADOS DE AMOSTRA / -->
                <div class="item">
                  <button
                    type="button"
                    class="btn ripple"
                    :class="cssBtn(isShowSampling)"
                    @click="setIsShowSampling(!isShowSampling)"
                  >
                    <i class="icone material-icons">group</i>
                  </button>

                  <span class="text">Dados de Amostra</span>
                </div>
              </div>
              <div class="item_column">
                <!-- / PROGRAMAS CONCORRENTES / -->
                <div class="item item_r">
                  <button
                    type="button"
                    class="btn ripple"
                    :class="cssBtn(isShowCompetitor)"
                    @click="setIsShowCompetitor(!isShowCompetitor)"
                  >
                    <i class="icone material-icons">view_week</i>
                  </button>
                  <span class="text">Programas Concorrentes</span>
                </div>
              </div>
            </div>

            <div class="item_row">
              <div class="item_column">
                <!-- / PROGRAMAÇÃO PERSONALIZADA / -->
                <div class="item">
                  <button
                    type="button"
                    class="btn ripple"
                    :class="cssBtn(usePrograms)"
                    @click="setUsePrograms(!usePrograms)"
                  >
                    <i class="icone material-icons">view_week</i>
                  </button>
                  <span class="text">Programação personalizada</span>
                </div>
              </div>
              <div class="item_column">
                <!-- / PROGRAMAÇÃO SEGMENTADA / -->
                <div class="item">
                  <button
                    type="button"
                    class="btn ripple"
                    :disabled="isScheduleDisabled"
                    :class="cssBtn(useSchedule)"
                    @click="setUseSchedule(!useSchedule)"
                  >
                    <i class="icone material-icons">view_week</i>
                  </button>
                  <span class="text">Programação segmentada</span>
                </div>
              </div>
              <div class="item_row">
                <div class="item_column">
                  <div class="item">
                    <button
                      type="button"
                      class="btn ripple"
                      :class="cssBtn(isMarketsWeightVisible)"
                      @click="() => setMarketsWeightValue(!isMarketsWeightVisible)"
                    >
                      <i class="icone material-icons">fitness_center</i>
                    </button>
                    <span class="text">Peso das praças</span>
                  </div>
                </div>
              </div>
              <!-- // -->
            </div>
          </div>

          <div class="options">
            <div class="title">Dados</div>

            <div class="item">
              <button
                type="button"
                class="btn ripple"
                :class="cssBtn(defaultValue === 'audience')"
                @click="setDefaultValue('audience')"
              >
                <i class="icone material-icons">event_seat</i>
              </button>
              <span class="text">Audiência</span>
            </div>

            <div class="item" :class="{ disabled: isIndividualsView }">
              <button
                type="button"
                class="btn ripple"
                :class="cssBtn(defaultValue === 'shareTLE')"
                @click="setDefaultValue('shareTLE')"
              >
                <i class="icone material-icons">donut_large</i>
              </button>
              <span class="text">Share TLE</span>
            </div>

            <div class="item" :class="{ disabled: isIndividualsView }">
              <button
                type="button"
                class="btn ripple"
                :class="cssBtn(defaultValue === 'audienceAndShareTLE')"
                @click="setDefaultValue('audienceAndShareTLE')"
              >
                <div class="stacked-icons">
                  <i class="icone material-icons">donut_large</i>
                  <i class="icone material-icons">event_seat</i>
                </div>
              </button>
              <span class="text">Audiência e Share TLE</span>
            </div>

            <div class="item disabled">
              <button
                type="button"
                class="btn ripple"
                :class="cssBtn(defaultValue === 'shareTL')"
                @click="setDefaultValue('shareTL')"
              >
                <i class="icone material-icons">donut_small</i>
              </button>
              <span class="text">Share TL</span>
            </div>

            <p class="disclaimer" v-if="defaultValue === 'shareTL'">
              Só é possível visualizar a audiência e share juntos na visão de tabela. Nas demais visões, será exibida apenas a audiência.
            </p>

            <p class="disclaimer" v-if="isIndividualsView">
              No universo "Individual (#)" só é possível visualizar a audiência em absoluto.
            </p>
          </div>


         <div class="options">
            <div class="title">Dados Simulcast</div>

            <div  class="item">
              <button
                type="button"
                class="btn ripple"
                :class="cssBtn(simulcastValue === 'users')"
                @click="setSimulcastValue('users')"
              >
                <i class="icone material-icons">person</i>
              </button>
              <span class="text">Usuários Conectados</span>
            </div>

            <div class="item">
              <button
                type="button"
                class="btn ripple"
                :class="cssBtn(simulcastValue === 'audience')"
                @click="setSimulcastValue('audience')"
              >
                <i class="icone material-icons">event_seat</i>
              </button>
              <span class="text">Audiência</span>
            </div>
         </div>



          <div class="options">
            <div class="title">Média Histórica</div>

            <div class="item">
              <button
                type="button"
                class="btn ripple"
                :class="cssBtn(performanceHistory === 2)"
                @click="setPerformanceHistory(2)"
              >
                <i class="icone material-icons">view_stream</i>
              </button>
              <span class="text">2 semanas</span>
            </div>

            <div class="item">
              <button
                type="button"
                class="btn ripple"
                :class="cssBtn(performanceHistory === 4)"
                @click="setPerformanceHistory(4)"
              >
                <i class="icone material-icons">view_headline</i>
              </button>
              <span class="text">4 semanas</span>
            </div>
          </div>
          <!-- // -->
        </div>
        <div class="loader" :class="cssSpinner">
          <svg class="circular" viewBox="25 25 50 50">
            <circle
              class="path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke-width="3"
              stroke-miterlimit="8"
            />
          </svg>
        </div>
        <div class="footer">
          <div v-if="!isRangeTimeSelectedBeforeOrEqualLastTimeUpdated" class="feedback" :class="cssFeedBack">
            {{ `Por favor, escolha um período antes ou igual ao horário de última atualização:
            ${this.lastTimeUpdated.format('HH:mm')}.` }}
        </div>
        <div v-if="!isStartSliderLowerOrEqualEndSlider" class="feedback" :class="cssFeedBack">
          {{ `A hora inicial da grade deve ser menor do que a hora final.` }}
        </div>
          <button
            type="button"
            class="btn btn_full btn_secondary ripple"
            @click="applyPreferences()"
          >Aplicar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import { generateTimeRanges } from '../../utils/time-utils';

export default {
  components: {
    VueSlider,
  },
  data() {
    return {
      isIndividualsView: null,
      isMarketView: null,
      isMinuteView: null,
      performanceHistory: null,
      isShowCompetitor: null,
      isShowSampling: null,
      defaultValue: null,
      simulcastValue: null,
      day: 0,
      rangeTime: ['04:00', '03:59'],
      timeData: [...generateTimeRanges(1, 4, 24), ...generateTimeRanges(1, 0, 4), '03:59'],
      feedBackOpen: null,
      loaderOpen: null,
      usePrograms: false,
      useSchedule: false,
      isScheduleDisabled: false,
      isMarketsWeightVisible: false,
    };
  },
  computed: {
    ...mapState(["system"]),
    ...mapGetters(["marketDays", "lastTimeUpdated", 'individualsMarkets']),
    ...mapGetters({ filteredMarkets: 'markets' }),
    isInHomePage() {
      return this.$route.name === "Home";
    },
    navigationCss() {
      const { isUserPreferencesOpen } = this.system;
      return {
        in: isUserPreferencesOpen,
        out: isUserPreferencesOpen === false
      };
    },
    cssFeedBack() {
      return {
        "feedback--show": this.feedBackOpen === true,
        "feedback--hide": this.feedBackOpen === false
      };
    },
    cssSpinner() {
      return {
        "loader--show": this.loaderOpen === true,
        "loader--hide": this.loaderOpen === false
      };
    },
    currentGridDate() {
      return moment(this.marketDays[0].startsIn).format('YYYY-MM-DD');
    },
    selectedGridDate() {
      return moment(this.marketDays[this.day].startsIn).format('YYYY-MM-DD');
    },
    // Deve me retornar uma data e horário inicial da grade atual selecionada no slider
    startSliderDateTime() {
      const [startTime] = this.rangeTime;
      const [startTimeHour, startTimeMinute] = startTime.split(':');
      const startDateTime = moment(this.selectedGridDate, 'YYYY-MM-DD')
      .set({ hours: startTimeHour, minutes: startTimeMinute, seconds: 0 });

      if (this.isStartSliderOnNextDay) {
        startDateTime.add(1, 'd');
      }

      return startDateTime.format();
    },
    // Deve me retornar uma data e horário final da grade atual selecionada no slider
    endSliderDateTime() {
      const [, endTime] = this.rangeTime;
      const [endTimeHour, endTimeMinute] = endTime.split(':');
      const endDateTime = moment(this.selectedGridDate, 'YYYY-MM-DD')
      .set({ hours: endTimeHour, minutes: endTimeMinute, seconds: 0 });

      if (this.isEndSliderOnNextDay) {
        endDateTime.add(1, 'd');
      }

      return endDateTime.format();
    },
    isStartSliderOnNextDay() {
      const [startTime] = this.rangeTime;
      return startTime < '04:00';
    },
    isEndSliderOnNextDay() {
      const [, endTime] = this.rangeTime;
      return endTime >= '00:00' && endTime < '04:00';
    },
    isRangeTimeSelectedBeforeOrEqualLastTimeUpdated() {
      // A data/hora final deve ser sempre menor ou igual a data/hora da última
      //  atualização se o dia selecionado for o atual
      if (this.selectedGridDate === this.currentGridDate) {
        return this.endSliderDateTime <= this.lastTimeUpdated.format();
      }
      return true;

    },
    isStartSliderLowerOrEqualEndSlider() {
      // A data/hora inicial deve ser sempre menor que a data/hora final
      return this.startSliderDateTime <= this.endSliderDateTime;
    },
    isValidRangeTimeSelection() {
      return this.isRangeTimeSelectedBeforeOrEqualLastTimeUpdated && this.isStartSliderLowerOrEqualEndSlider;
    },

  },
  created() {
    this.defineEnableBtns();
  },
  methods: {
    cssBtn(condition) {
      return {
        selected: condition === true,
        option: condition === false
      };
    },
    setIsMarketView(condition) {
      this.isMarketView = condition;
    },
    setIsMinuteView(condition) {
      this.isMinuteView = condition;
    },
    setIsIndividualsView(condition) {
      this.isIndividualsView = condition;
      if (condition) {
        this.setDefaultValue('audience');
        this.setIsMarketView(true);
      }
    },
    setIsShowSampling(condition) {
      this.isShowSampling = condition;
    },
    setIsShowCompetitor(condition) {
      this.isShowCompetitor = condition;
      this.changeScheduleBtnState();
    },
    setUsePrograms(condition) {
      this.usePrograms = condition;
      this.isScheduleDisabled = !this.usePrograms;
      this.changeScheduleBtnState();
    },
    setUseSchedule(condition) {
      this.useSchedule = condition;
    },
    setDefaultValue(value) {
      this.defaultValue = value;
    },
    setSimulcastValue(value) {
      this.simulcastValue = value;
    },

    setPerformanceHistory(weekNum) {
      this.performanceHistory = weekNum;
    },
    setMarketsWeightValue(value) {
      this.isMarketsWeightVisible = value;
    },
    closeUserPreferences() {
      setTimeout(() => {
        // delay to not be too fast
        this.$store.dispatch("closeUserPreferences");
        this.defineEnableBtns();
      }, 400);
    },
    changeScheduleBtnState() {
      if (this.isShowCompetitor || this.usePrograms) {
        this.isScheduleDisabled = false;
      } else {
        this.isScheduleDisabled = true;
        this.useSchedule = false;
      }
    },
    defineEnableBtns() {
      // need that
      // this.performanceHistory = this.settings.performanceHistory;
      const {
        isIndividualsView,
        isMarketView,
        isMinuteView,
        dateOptions,
        isShowSampling,
        isShowCompetitor,
        usePrograms,
        defaultValue,
        simulcastValue,
        useSchedule,
        performanceHistory,
        isMarketsWeightVisible,
      } = this.$store.state.settings;
      this.setDefaultValue(defaultValue);
      this.setIsMarketView(isMarketView);
      this.setIsIndividualsView(isIndividualsView);
      this.setIsMinuteView(isMinuteView);
      this.setIsShowSampling(isShowSampling);
      this.setIsShowCompetitor(isShowCompetitor);
      this.setSimulcastValue(simulcastValue);
      this.setUseSchedule(useSchedule);
      this.setUsePrograms(usePrograms);
      this.changeScheduleBtnState();
      this.setPerformanceHistory(performanceHistory);
      this.setMarketsWeightValue(isMarketsWeightVisible);
      const { day, startTime, endTime } = dateOptions;
      this.day = day;
      this.rangeTime = (startTime && endTime) ? [startTime, endTime] :  ['04:00', this.lastTimeUpdated.format('HH:mm')];
    },
    closeValidation() {
      if (this.feedBackOpen) {
        this.feedBackOpen = false;
      }
    },
    openValidation() {
      this.feedBackOpen = true;
    },
    animateValidationBox() {
      // Show the validation box -> start gradient animation -> close
      this.openValidation();
      clearTimeout(this.timerToHideValidation);
      this.isValidationShow = true;
      setTimeout(() => {
        this.isValidationShow = false;
        this.timerToHideValidation = setTimeout(() => {
          this.closeValidation();
        }, 3000);
      }, 750);
    },
    changeDay(day) {
      const isCurrentDaySelected = day === 0;
      if (isCurrentDaySelected) {
        this.initializeSliderOptionsAsCurrentDaySelected();
      } else {
        this.rangeTime = ['04:00', '03:59'];
      }
      this.day = day;
    },
    initializeSliderOptionsAsCurrentDaySelected() {
      let [startTime, endTime] = this.rangeTime;

      const lastTimeUpdated = this.lastTimeUpdated.format('HH:mm');

      if (startTime > lastTimeUpdated || this.isStartSliderOnNextDay) startTime = '04:00';
      if (endTime > lastTimeUpdated || this.isEndSliderOnNextDay) endTime = lastTimeUpdated;

      this.rangeTime = [startTime, endTime];
    },
   // Atualiza o slider com base nos valores inseridos manualmente pelos inputs de time.
   changeStartTimeValue(event) {
      this.rangeTime = [event.target.value, this.rangeTime[1]];
    },
    changeEndTimeValue(event) {
      this.rangeTime = [this.rangeTime[0], event.target.value];
    },
    clearRangeTimeOptions() {
      const isCurrentDaySelected = this.day === 0;

      const [startTime, endTime] = ['04:00', '03:59'];

      if (isCurrentDaySelected) {
        this.rangeTime = [startTime, this.lastTimeUpdated.format('HH:mm')];
      } else {
        this.rangeTime = [startTime, endTime];
      }
    },
    showLoading() {
      this.loaderOpen = true;
    },
    closeLoading() {
      this.loaderOpen = false;
    },
    applyPreferences() {
      this.showLoading();

      const [startTime, endTime] = this.rangeTime;

      if (!this.isValidRangeTimeSelection) {
        this.animateValidationBox();
        return;
      }
      if (this.isIndividualsView !== this.$store.state.settings.isIndividualsView) {
        this.$store.dispatch("selectIsIndividualsView", this.isIndividualsView);
        this.$store.dispatch('selectMarket', this.filteredMarkets[0]);
      }
      this.$store.dispatch("selectIsMarketView", this.isMarketView);
      this.$store.dispatch("selectIsMinuteView", this.isMinuteView);
      this.$store.dispatch("selectDefaultValue", this.defaultValue);
      this.$store.dispatch("selectIsShowSampling", this.isShowSampling);
      this.$store.dispatch("selectIsShowCompetitor", this.isShowCompetitor);
      this.$store.dispatch("selectUsePrograms", this.usePrograms);
      this.$store.dispatch("selectUseSchedule", this.useSchedule);
      this.$store.dispatch("selectDate", {
        day: this.day,
        startTime,
        endTime,
      });
      this.$store.dispatch("selectPerformanceHistory", this.performanceHistory);
      this.$store.dispatch("selectIsMarketsWeightVisible", this.isMarketsWeightVisible);

      this.closeLoading();
      this.closeUserPreferences();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.navigation_mobile {
  display: none;
}



// SMARTPHONES
@media (max-width: 600px) {

  // Navigation Mobile
  .navigation_mobile {
    position: fixed;
    display: block;
    z-index: 2;
    width: 100%;
    height: 100%;
    font-family: $secondary-typo;
    font-weight: 400;
    font-size: 0.88em;
    line-height: 1.4em;
    color: $color-gray-dark;
    pointer-events: none;

    .overlay {
      display: flex;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      background-color: $color-gray-lighter;
      opacity: 0;

      .content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        background-color: $color-gray-back;

        // Topo
        .topo {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          position: sticky;
          position: -webkit-sticky;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 64px;
          background: linear-gradient(140deg,
              $color-primary-light,
              $color-secondary );
          background-size: 100% 100%;
          box-shadow: 0 8px 16px rgba($color-gray-darker, 0.16);
          animation: fadeInDown 0.4s ease-in-out forwards;

          // Botão
          .btn {
            width: 56px;
            height: 56px;
            margin: 0;
            padding: 0;
            box-shadow: none;

            i {
              margin: 4px 0 0 0;
              padding: 0;
            }

            // Ripple Effect
            &.ripple {
              background-position: center;
              background: rgba($color-gray-back, 0);
              transition: background 0.56s ease-in-out, opacity 0.8s ease-in-out;

              & i {
                color: $color-gray-lighter;
                transition: color 0.56s ease-in-out;
              }

              &:hover {
                background: rgba($color-gray-back, 0.04) radial-gradient(circle,
                    transparent 1%,
                    rgba($color-gray-back, 0.04) 1%) center/15000%;
                color: $color-gray-main;

                & i {
                  color: $color-gray-lighter;
                }
              }

              &:active {
                background-color: rgba($color-gray-lighter, 0.04);
                background-size: 100%;
                transition: background 0s;
              }
            }
          }

          // ------------

          .title {
            font-family: $secondary-typo;
            font-weight: 500;
            font-size: 0.88em;
            text-transform: uppercase;
            color: $color-gray-lighter;
            margin: 0 0 0 8px;
          }
        }

        // Hack Firefox Only
        @-moz-document url-prefix() {
          .topo {
            position: fixed;
          }
        }

        // ---------------

        // Footer
        .footer {
          position: -webkit-sticky;
          position: sticky;
          bottom: 0;
          left: 0;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          width: 100%;
          margin-top: auto;
          background-color: $color-gray-lighter;
          animation: fadeInUp 0.4s ease-in-out forwards;
          box-shadow: 0 0 16px rgba($color-gray-dark, 0.08);

          .btn_primary {
            border: none;

            &:hover {
              border: none;
            }

            &.ripple {
              background-position: center;
              background: $color-secondary;
              border: solid 2px $color-secondary;
              color: rgba($color-gray-lighter, 1);
              transition: background 0.4s ease-in-out, opacity 0.8s ease-in-out,
                color 0.4s ease-in-out;

              &:hover {
                border: solid 2px $color-secondary;
                background: $color-secondary radial-gradient(circle, transparent 1%, $color-secondary 1%) center/15000%;

                & i,
                &.day {
                  color: $color-gray-lighter;
                }
              }

              &:active {
                background-color: rgba($color-secondary-dark, 0.64);
                background-size: 100%;
                transition: background 0s;
              }
            }

            // --------
            // --------
          }

          .btn_secondary {
            border: none;

            &:hover {
              border: none;
            }

            &.ripple {
              background-position: center;
              background: $color-gray-back;
              color: $color-primary;
              transition: background 0.4s ease-in-out, opacity 0.8s ease-in-out,
                color 0.8s ease-in-out;

              &:hover {
                background: $color-gray-back radial-gradient(circle, transparent 1%, $color-gray-back 1%) center/15000%;
                color: $color-primary;
              }

              &:active {
                background-color: rgba($color-gray-light, 0.48);
                background-size: 100%;
                transition: background 0s;
              }
            }

            // --------
            // --------
          }
        }

        // Hack Firefox Only
        @-moz-document url-prefix() {
          .footer {
            position: fixed;
          }
        }

        // Meio
        .middle {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 8px 0 140px 0;
          overflow-y: auto;
          animation: fadeIn 0.4s ease-in-out forwards;

          // Options
          .options {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            padding: 0 16px 16px 16px;
            border-bottom: solid 1px $color-gray-back;
            overflow: hidden;

            .item-date-input {
              margin: 16px 4px;
              text-align: center;
              padding: 4px;
              border-radius: 25px;
            }

            // Title
            .title {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
              width: 100%;
              box-sizing: border-box;
              padding: 8px;
              margin: 16px 0;

              font-family: $secondary-typo;
              font-weight: 400;
              font-size: 0.88em;
              letter-spacing: 0;
              color: $color-gray-main;

              &::after,
              &::before {
                content: "";
                position: absolute;
                top: 16px;
                width: 32%;
                height: 1px;
                background-color: rgba($color-gray-light, 0.4);
              }

              &::after {
                right: 0;
              }

              &::before {
                left: 0;
              }
            }

            // --------------------------
            // --------------------------

            &:last-child {
              border: none;
            }

            &.disabled {
              opacity: 0.4;
              pointer-events: none;
            }

            .item_column {
              float: left;
              width: 50%;
            }

            /* Clear floats after the columns */
            .item_row:after {
              content: "";
              display: table;
              clear: both;
            }

            // Item
            .item {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              width: 150px;
              padding: 8px 0 0 0;
              margin: 0 2px 8px 2px;

              &.disabled {
                opacity: 0.24;
                pointer-events: none;
              }

              &.full {
                width: 100%;
                margin: 0;
              }

              // Botao
              .btn {
                width: 56px;
                height: 56px;
                border-radius: 100%;
                box-shadow: none;

                // Icons
                i {
                  width: auto;
                  padding: 0;
                  margin: 0;
                  background-color: transparent;
                  font-size: 2em;
                  line-height: 2em;
                }

                .stacked-icons {
                  position: relative;

                  i {
                    position: absolute;
                    top: 12px;
                    left: 5.5px;
                    font-size: 1em;
                    margin: 0;

                    &:first-child {
                      position: static;
                      font-size: 2em;
                    }
                  }
                }

                // Text
                &.day {
                  padding: 0;
                  margin: 0;
                  font-family: $primary-typo;
                  font-size: 1em;
                  text-align: center;
                  letter-spacing: 0;
                }

                &.selected {
                  &.ripple {
                    background-position: center;
                    background: $color-secondary;
                    border: solid 2px $color-secondary;
                    transition: background 0.4s ease-in-out,
                      opacity 0.8s ease-in-out;

                    & i,
                    &.day {
                      color: rgba($color-gray-lighter, 1);
                      transition: color 0.4s ease-in-out;
                    }

                    &:hover {
                      border: solid 2px $color-secondary;
                      background: $color-secondary radial-gradient(circle,
                          transparent 1%,
                          $color-secondary 1%) center/15000%;

                      & i,
                      &.day {
                        color: $color-gray-lighter;
                      }
                    }

                    &:active {
                      background-color: rgba($color-secondary-dark, 0.64);
                      background-size: 100%;
                      transition: background 0s;
                    }
                  }
                }

                // --------
                // --------

                &.option {
                  &.ripple {
                    background-position: center;
                    background: $color-gray-back;
                    border: solid 2px $color-secondary;
                    transition: background 0.4s ease-in-out,
                      opacity 0.8s ease-in-out;

                    & i,
                    &.day {
                      color: $color-secondary;
                      transition: color 0.4s ease-in-out;
                    }

                    &:hover {
                      border: solid 2px $color-secondary;
                      background: $color-gray-back radial-gradient(circle,
                          transparent 1%,
                          $color-gray-back 1%) center/15000%;

                      & i,
                      &.day {
                        color: $color-secondary;
                      }
                    }

                    &:active {
                      background-color: rgba($color-gray-light, 1);
                      background-size: 100%;
                      transition: background 0s;
                    }
                  }
                }

                // --------
                // --------
              }

              .text {
                width: 100%;
                margin: 8px 0 0 0;
                font-family: $secondary-typo;
                font-size: 0.8em;
                font-weight: 600;
                font-style: italic;
                text-align: center;
                color: $color-gray-dark;
                text-transform: uppercase;
                word-break: break-word;
                line-height: 1.2em;

                .week {
                  font-weight: 600;
                }

                .month {
                  font-weight: 400;
                  color: $color-gray-main;
                }
              }

              // TIME
              .time {
                width: 40%;
                margin: 0 8px;
                position: relative;

                input {
                  position: relative;
                  display: block;
                  width: 100%;
                  height: 56px;
                  background-color: $color-gray-back;
                  border-radius: 0;
                  border: none;
                  border-bottom: solid 2px $color-secondary;
                  padding: 0;

                  font-family: $secondary-typo;
                  font-size: 1em;
                  font-weight: 500;
                  color: $color-secondary;
                  text-align: center;
                  text-indent: 6px;

                  -webkit-appearance: textfield;
                  -moz-appearance: textfield;
                }

                i {
                  position: absolute;
                  z-index: 1;
                  top: 19px;
                  left: 0;
                  width: auto;
                  padding: 0;
                  background-color: transparent;
                  font-size: 1.4em;
                  color: $color-secondary;
                }
              }

              // --------------
              // --------------
            }

            .disclaimer {
              font-size: .85em;
              color: $color-gray-lighter;
              margin-bottom: 0;
              background-image: linear-gradient(-224deg, $color-secondary 0%, $color-primary 100%);
              padding: 10px;
              border-radius: 4px;
              font-weight: bold;
            }
          }

          // --------------
        }

        // Hack Firefox Only
        @-moz-document url-prefix() {
          .middle {
            margin: 48px 0;
            height: calc(100% - 116px);
            box-sizing: border-box;
          }
        }
      }
    }

    // Validation
    .feedback {
      position: absolute;
      bottom: 0;
      z-index: 4;
      width: 100%;
      height: 64px;
      opacity: 0;
      pointer-events: none;
      box-sizing: border-box;
      padding: 16px 14px;

      font-family: $secondary-typo;
      font-size: 0.8em;
      font-weight: 500;
      line-height: 1.4em;
      color: rgba($color-gray-lighter, 1);
      border-radius: 4px;

      background: linear-gradient(233deg, #630d58, #a8203c);
      background-size: 100% 100%;

      // Show
      &.feedback--show {
        animation: fadeInUp 0.8s ease-in-out forwards;
      }

      // Hide
      &.feedback--hide {
        animation: fadeOutDown 0.8s ease-in-out forwards;
      }
    }
  }

  // Entrada
  &.in {
    pointer-events: visible;

    .overlay {
      animation: fadeIn 0.2s ease-in-out forwards;

      .content {
        animation: fadeIn 0.8s ease-in-out forwards;
      }
    }
  }

  // Saída
  &.out {
    pointer-events: none;

    .overlay {
      opacity: 1;
      animation-delay: 0.2s !important;
      animation: fadeOut 0.8s ease-in-out forwards;

      .content {
        opacity: 1;
        animation: fadeOut 0.6s ease-in-out forwards;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .item_column {
    width: 50% !important;
  }
}

@media screen and (max-width: 320px) {
  .item_column {
    width: 100% !important;
    margin-left: 25%;
  }
}

@media screen and (max-width: 180px) {
  .item_column {
    width: 100% !important;
    margin-left: 5%;
  }
}

// iPhone 5
@media screen and (device-aspect-ratio: 40/71) {
  // Navigation Mobile
  .navigation_mobile {
    .overlay {
      .content {
        // Footer
        .footer {
          position: sticky;
          position: -webkit-sticky;
          bottom: 0;

          .btn {
            font-size: 0.8em !important;
          }
        }
        // --------

        // Middle
        .middle {
          display: block;
          overflow-y: scroll;

          .options {
            .item {
              margin: 0 16px;

              .btn {
                width: 64px;
                height: 64px;
              }
            }
          }
        }
      }
    }
  }
}
.slider-options {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  gap: 20px;
}

.time {
  width: 90%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.slider-title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5px;
  text-align: bottom ;
  margin: 0px;
  font-family: $secondary-typo;
  font-weight: 400;
  font-size: 0.88em;
  letter-spacing: 0;
  color: $color-gray-main;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 16px;
    width: 32%;
    height: 1px;
    background-color: rgba($color-gray-light, 0.4);
  }

  &::after {
    right: 0;
  }

  &::before {
    left: 0;
  }
}

.time > input {
  width: 47%;
  background-color: $color-gray-back;
  border-radius: 0;
  border: none;
  border-bottom: solid 2px $color-secondary;
  padding: 10px;

  font-family: $secondary-typo;
  font-size: 1em;
  font-weight: 500;
  color: $color-secondary;
  text-align: center;
  text-indent: 6px;

  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

.clear-btn {
  font-size: 9px;
  margin: 0;
  height: 30px;
  width: 60px;
  border-radius: 12px;
}
</style>
