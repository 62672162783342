<template>
  <div id="scrollArea" ref="scrollingTable"
       class="table_container"
       :class="{
         loading: firstLoading, 'kpi_on': false && settings.isKpi && settings.isMarketView,
         desc: settings.isDescending,
         asc: !settings.isDescending }"
  >
    <div class="loading_inner">
      <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6"
                stroke-linecap="round" cx="33" cy="33" r="30"
        />
      </svg>
    </div>

    <table class="table minutes-view shadow" :class="tableCss">
      <thead :style="`${settings.defaultValue === 'audienceAndShareTLE' ? 'height: 80px;' : ''}`">
        <tr>
          <th>
            <button class="btn sort ripple"
                    :class="{'descending': settings.isDescending}"
                    @click="changeIsDescending"
            >
              <div class="chevron" />
              <i class="icone material-icons">
                access_time
              </i>
            </button>
            <!-- fim da ordenação -->
            <div class="fullscreen">
              <template v-if="settings.isMarketView">
                {{ settings.market.exhibitionName }}
              </template>
              <img v-else-if="getNetworkLogo(settings.tvNetworkId, true)" :src="getNetworkLogo(settings.tvNetworkId, true)">
              <div v-else :style="{ color: getNetworkColor(settings.tvNetworkId, true) }">
                {{ settings.tvNetworkId }}
              </div>
            </div>
          </th>
          <!-- Market Table-->
          <th v-for="column in secondaryColumns" :key="column.id" :style="{ 'min-width': `${calcWidth()}px` }">
            <img v-if="getNetworkLogo(column[idName])" :src="getNetworkLogo(column[idName])">
            <div v-else :style="{ color: getNetworkColor(column[idName]) }" class="tag">
              {{ transNetworkNameById(column[idName]) }}
              <span v-if="settings.isMarketsWeightVisible" :title="column.id === 'RPP' ? 'Posse' : 'Peso'" class="tag-weight">
               {{ column.weight ? column.weight : '-' }}
              </span>
            </div>
            <div class="tv_now" :class="programNowCss">
              <div class="tv_title" :style="{ color: getNetworkColor(column[idName]) }">
                {{ transNetworkNameById(column[idName]) }}
              </div>
              <div class="tv_program">
                <div v-if="settings.isMarketView">
                  {{ showProgramMouseOver(settings.market, column[idName]) }}
                </div>
                <div v-else>
                  {{ showProgramMouseOver(column, settings.tvNetworkId) }}
                </div>
              </div>
            </div>
          </th>
          <th v-if="showSamplingColumn" :style="{ 'min-width': `${calcWidth()}px` }">
            <div>
              Amostra
            </div>
          </th>
        </tr>
        <tr class="aud-and-share-header" v-if="settings.defaultValue === 'audienceAndShareTLE'">
          <td></td>
          <td v-for="(_, i) in secondaryColumns" :key="i">
            AUD | SHR
          </td>
        </tr>
        <tr class="current-program-info">
          <th />
          <th
            v-for="column in secondaryColumns"
            :key="column.id"
            :style="{ 'min-width': `${calcWidth()}px` }"
          >
            <div v-if="settings.isMarketView">
              {{ showProgramLastLine(settings.market, column[idName]) }}
            </div>
            <div v-else>
              {{ showProgramLastLine(column, settings.tvNetworkId) }}
            </div>
          </th>
          <th v-if="showSamplingColumn" :style="{ 'min-width': `${calcWidth()}px` }">
            <div>
              --
            </div>
          </th>
        </tr>
      </thead>
      <tbody id="contentArea" v-longpress="tbodyMouseClick"
             @mouseover="tbodyMouseOver" @mouseleave="tbodyMouseLeave"
      />
      <tfoot :class="averagesClass">
        <tr v-if="selectedAverages">
          <td>
            Média
            <br>
            {{ formatDateMS(globoDate.startsIn) }} a {{ formatDateMS(globoDate.endsIn || 'agora') }}
          </td>
          <td v-for="tvNetwork in secondaryColumns" :key="tvNetwork.id"
              :class="findAveragesWinners('selectedAverages', tvNetwork.id, tvNetwork.id)"
          >
            <span
              v-if="findAverages('selectedAverages', tvNetwork.id, tvNetwork.id, dataTypeSelectedAsList[0]) === '-'"
            >-</span>
            <span v-else>
              <template v-for="(dataType, i) in dataTypeSelectedAsList">
                {{ i > 0 ? ' | ' : '' }}
                <AnimatedNumber
                  :key="`selectedAverages-${dataType}`"
                  :value="findAverages('selectedAverages', tvNetwork.id, tvNetwork.id, dataType)"
                  :format-value="(value) => {
                    return (value === 100 ? 100 : formatValue(value, dataType));
                  }"
                  tag="span"
                />
              </template>
            </span>
          </td>
          <td v-if="showSamplingColumn" :style="{ 'min-width': `${calcWidth()}px` }">
            <AnimatedNumber :value="findSamplingAverage('selectedAverages')" />
          </td>
        </tr>
        <tr v-else-if="programNowAverages">
          <td>
            Média<br>
            Prog. Ar
          </td>
          <td v-for="tvNetwork in secondaryColumns" :key="tvNetwork.id"
              :class="findAveragesWinners('programNowAverages', tvNetwork.id, tvNetwork.id)"
              :style="{ 'min-width': `${calcWidth()}px` }"
          >
            <span
              v-if="findAverages('programNowAverages', tvNetwork.id, tvNetwork.id, dataTypeSelectedAsList[0]) === '-'"
            >-</span>
            <span v-else>
              <template v-for="(dataType, i) in dataTypeSelectedAsList">
                {{ i > 0 ? ' | ' : '' }}
                <AnimatedNumber
                  :key="`programNowAverages-${dataType}`"
                  :value="findAverages('programNowAverages', tvNetwork.id, tvNetwork.id, dataType)"
                  :format-value="(value) => {
                    return (value === 100 ? 100 : formatValue(value, dataType));
                  }"
                  tag="span"
                />
              </template>
            </span>
          </td>
          <td v-if="showSamplingColumn" :style="{ 'min-width': `${calcWidth()}px` }">
            <AnimatedNumber :value="findSamplingAverage('programNowAverages')" />
          </td>
        </tr>
        <tr v-if="dayAverages">
          <td>
            Média
            <br>07:00 a 00:00
          </td>
          <td v-for="tvNetwork in secondaryColumns" :key="tvNetwork.id"
              :class="findAveragesWinners('dayAverages', tvNetwork.id, tvNetwork.id)"
              :style="{ 'min-width': `${calcWidth()}px` }"
          >
            <span
              v-if="findAverages('dayAverages', tvNetwork.id, tvNetwork.id, dataTypeSelectedAsList[0]) === '-'"
            >-</span>
            <span v-else>
              <template v-for="(dataType, i) in dataTypeSelectedAsList">
                {{ i > 0 ? ' | ' : '' }}
                <AnimatedNumber
                  :key="`dayAverages-${dataType}`"
                  :value="findAverages('dayAverages', tvNetwork.id, tvNetwork.id, dataType)"
                  :format-value="(value) => {
                    return (value === 100 ? 100 : formatValue(value, dataType));
                  }"
                  tag="span"
                />
              </template>
            </span>
          </td>
          <td v-if="showSamplingColumn" :style="{ 'min-width': `${calcWidth()}px` }">
            <AnimatedNumber :value="findSamplingAverage('dayAverages')" />
          </td>
        </tr>
      </tfoot>
    </table>

    <div v-if="!firstLoading && isLoadingByScroll && isScrollInBottom()" class="loading_small">
      <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6"
                stroke-linecap="round" cx="33" cy="33" r="30"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import Clusterize from 'clusterize.js';
import _ from 'lodash';
import AnimatedNumber from '@/components/modules/AnimatedNumber.vue';
import transformMixin from '@/utils/transformMixin';


export default {
  components: {
    AnimatedNumber,
  },
  mixins: [transformMixin],
  data() {
    return {
      clusterize: null,
      currentAudienceTimeout: null,
      currentAudienceInterval: null,
      firstLoading: true,
      didFinsh: false,
      minuteLines: null,
      programsMouseOver: null,
      programsLastLine: {},
      scrollLength: 0,
      currentScrollTimeout: null,
      lastScrollTop: 0,
      isScrollHorizontal: null,
      currentScrollHorizontalTimeout: null,
      lastScrollLeft: 0,
    };
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters(['audiences', 'scrollId', 'markets', 'tvNetworks', 'dayAverages', 'selectedAverages', 'programNowAverages',
      'marketDays', 'isLoadingByScroll', 'tvNetworks_v2', 'globoDate', 'schedules', 'networks', 'lastSchedules',
      'singleDefaultValue', 'dataTypeSelectedAsList']),

    secondaryColumns() {

      let marketTemp = this.markets.map(object => ({ ...object }));
      marketTemp = marketTemp.filter(item => item.exhibitionName != 'FLO');
      marketTemp = marketTemp.filter(item => item.exhibitionName != 'POA');
      marketTemp = marketTemp.filter(item => item.exhibitionName != 'MAN');
      marketTemp = marketTemp.filter(item => item.id != 'FOT');
      marketTemp = marketTemp.filter(item => item.id != 'RB');
      marketTemp = marketTemp.filter(item => item.id != 'MAC');
      marketTemp = marketTemp.filter(item => item.id != 'PVE');
      marketTemp = marketTemp.filter(item => item.id != 'BVI');
      marketTemp = marketTemp.filter(item => item.id != 'CAM');
      marketTemp = marketTemp.filter(item => item.id != 'SOR');
      marketTemp = marketTemp.filter(item => item.id != 'SJP');
      marketTemp = marketTemp.filter(item => item.id != 'BAU');
      marketTemp = marketTemp.filter(item => item.id != 'ITA');
      marketTemp = marketTemp.filter(item => item.id != 'CAB');
      marketTemp = marketTemp.filter(item => item.id != 'SLU');

      return this.settings.isMarketView ? this.tvNetworks : marketTemp;
    },
    idName() {
      return this.settings.isMarketView ? 'id' : 'exhibitionName';
    },
    programNowCss() {
      return {
        in: this.programsMouseOver,
        out: !this.programsMouseOver,
      };
    },
    tableCss() {
      return {
        'market-view': this.settings.isMarketView,
        in: this.isScrollHorizontal,
        out: this.isScrollHorizontal === false,
      };
    },
    averagesClass() {
      if (!this.programNowAverages && !this.dayAverages) return '';
      if (this.programNowAverages && this.dayAverages) return 'averages-two';
      return 'averages-one';
    },
    showSamplingColumn() {
      return this.settings.isShowSampling && this.settings.isMarketView;
    },
  },
  watch: {
    audiences(newValue) {
      const { source, sourceLength, scrollId } = newValue;
      if (!this.scrollId) this.$store.dispatch('setScrollId', scrollId);
      if (source.length === 0) {
        this.didFinsh = true;
      } else {
        this.syncAudienceDataIntoMinuteLineTable(source);
        if (scrollId === this.scrollId) {
          this.scrollLength += source.length;
          if (source.length && this.scrollLength < sourceLength) {
            this.getScroll();
          } else {
            this.scrollLength = 0;
          }
        }
      }
    },
    minuteLines(newValue, oldValue) {
      const table = this.$refs.scrollingTable;
      const { scrollTop, scrollHeight } = table;
      this.syncMinuteLineTableToClusterize(oldValue, newValue);

      if (!this.settings.isDescending) {
        this.lastScrollTop = scrollTop + table.scrollHeight - scrollHeight;
        table.scrollTop = this.lastScrollTop;
      }
    },
  },
  mounted() {
    this.$store.dispatch('selectIsButtonDownloadActivate', true);
    this.setupClusterize();
    this.setupCurrentAudienceOnTop();
    this.clearAndGetNewAudiences();
    this.unwatch = this.$store.watch(state => state.settings, () => {
      this.firstLoading = true;
      this.didFinsh = false;
      this.clearAndGetNewAudiences();
    }, { deep: true });
  },
  beforeDestroy() {
    this.$modal.hide('minuteDetails');
    this.$store.dispatch('setFullSizeView', false);
    this.unwatch();
    clearTimeout(this.currentAudienceTimeout);
    clearInterval(this.currentAudienceInterval);
  },
  methods: {
    getNetworkLogo(networkId, skipCheck) {
      if (!this.settings.isMarketView && !skipCheck) return '';
      return this.tvNetworks_v2[networkId] && this.tvNetworks_v2[networkId].customLogoPath
        ? this.tvNetworks_v2[networkId].customLogoPath : '';
    },
    getNetworkColor(networkId, skipCheck) {
      if (!this.settings.isMarketView && !skipCheck) return '';
      return this.tvNetworks_v2[networkId] && this.tvNetworks_v2[networkId].customColor
        ? this.tvNetworks_v2[networkId].customColor : '';
    },
    formatDateMS(date) {
      return moment(date).format('HH:mm');
    },
    async clearAndGetNewAudiences() {
      this.$store.dispatch('cancelRequests');
      this.$store.dispatch('setScrollId', null);
      this.separateLines();
      this.clusterize.clear();

      this.$store.dispatch('getDayAverages');
      this.$store.dispatch('getSelectedAverages');
      this.$store.dispatch('getProgramNowAverage');
      this.$store.dispatch('getLastSchedules');

      await Promise.all([
        this.$store.dispatch('getAudiences'),
      ]).then((res) => {
        if (res[0].source && res[0].source.length === 0) {
          this.$store.dispatch('showInnerError', { clicked: true });
        } else {
          this.setProgramsLastLine();
        }
      }).catch((error) => {
        if (error.response && error.response.status && error.response.status === 405) {
          this.$store.dispatch('showInnerError', {
            insides: ['notallowed_back', 'notallowed_item', 'notallowed_block'],
            subtitle: 'Você está fora da janela de restrições configurada para seu usuário. Por favor, retorne no horário definido para seu usuário.',
          });
        } else if (error.response && error.response.config) {
          if (/audience/.test(error.response.config.url)) {
            this.$store.dispatch('showInnerError', { clicked: true });
          }
        }
      });

      this.firstLoading = false;
    },
    separateLines() {
      this.minuteLines = {};
      const timenow = moment().startOf('day');
      const timeDiff = timenow.diff(moment(`${timenow.format('YYYY-MM-DD')}T00:00:00Z`).local(), 'hours');
      const colspan = this.secondaryColumns.length;

      const { startsIn, endsIn } = this.marketDays[this.settings.dateOptions.day];
      [startsIn, endsIn].forEach((time, key) => {
        if (time) {
          const date = moment(time).add(timeDiff, 'hours');
          if (key === 1) date.add(1, 'minutes');
          this.minuteLines[`${date.format('YYYY-MM-DDTHH:mm:SS')}`] = `<td><span></span>${date.subtract(1, 'days').format('dddd, LL')}</td><td></td><td></td><td colspan="${colspan}"></td>`;
        }
      });
    },
    changeIsDescending() {
      this.$store.dispatch('selectIsDescending', !this.settings.isDescending);
    },
    selectScrollDirection() {
      const table = this.$refs.scrollingTable;
      const timeout = 500;

      const sl = table.scrollLeft;
      if (sl) {
        if (sl !== this.lastScrollLeft) {
          this.isScrollHorizontal = true;
          clearTimeout(this.currentScrollHorizontalTimeout);
          this.currentScrollHorizontalTimeout = setTimeout(() => {
            this.isScrollHorizontal = false;
            const { scrollLeft } = table;
            this.lastScrollLeft = scrollLeft <= 0 ? 0 : scrollLeft; // For Mobile or negative scrolling
          }, timeout);
        }
      }

      const st = table.scrollTop;

      if (st === this.lastScrollTop) return;
      const diff = st - this.lastScrollTop;
      const changeStatus = this.settings.isDescending ? diff > 116 || diff < -400 : diff > 400 || diff < -116;
      if (changeStatus) {
        const isScrollDown = this.settings.isDescending
          ? st > this.lastScrollTop : st < this.lastScrollTop;

        this.$store.dispatch('setFullSizeView', isScrollDown);
      }

      clearTimeout(this.currentScrollTimeout);
      this.currentScrollTimeout = setTimeout(() => {
        const { scrollTop, scrollHeight, clientHeight } = table;
        if ((this.settings.isDescending && scrollTop === 0)
          || (!this.settings.isDescending && scrollTop + clientHeight === scrollHeight)) {
          this.$store.dispatch('setFullSizeView', false);
        }
        this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
        // Set programsLastLine
        this.setProgramsLastLine();
      }, timeout);
    },
    isScrollInBottom() {
      const table = this.$refs.scrollingTable;
      if (this.settings.isDescending) {
        return table.scrollTop + table.clientHeight >= table.scrollHeight - 1300;
      }

      return table.scrollTop <= 1300;
    },
    setProgramsLastLine() {
      const table = this.$refs.scrollingTable;
      const st = table.scrollTop;

      const clientHeight = this.settings.isDescending ? 0 : table.clientHeight;
      const minutes = parseInt((st + clientHeight) / 40, 10);
      const minuteKeys = Object.keys(this.minuteLines);
      const date = moment(minuteKeys[minutes]);
      this.programsLastLine = this.$store.getters.mapAllProgramsByDate(date);
    },
    getScroll() {
      if (!this.didFinsh) {
        try {
          this.$store.dispatch('getAudiencesByScrollId');
        } catch (error) {
          this.$store.dispatch('showInnerError', { clicked: true });
        }
      }
    },
    findSamplingAverage(type) {
      const { averages } = this[type];
      const market = this.settings.market.id;
      if (!market) return '-';
      const firstTvNetwork = Object.keys(averages[market])[0]; // get any tvnetwork
      if (!firstTvNetwork) return '-';
      const samplingAverage = averages[market][firstTvNetwork].sampling;
      return samplingAverage.toFixed(0);
    },
    findAverages(type, marketId, networkId, dataType) {
      if (this.settings.isMarketView) {
        // eslint-disable-next-line no-param-reassign
        marketId = this.settings.market.id;
      } else {
        // eslint-disable-next-line no-param-reassign
        networkId = this.settings.tvNetworkId;
      }

      // const { defaultValue } = this.settings;
      return this[type].averages[marketId] && this[type].averages[marketId][networkId]
        ? this[type].averages[marketId][networkId][dataType]
        : '-';
    },
    findAveragesWinners(type, marketId, networkId) {
      if (this.settings.isMarketView) {
        // eslint-disable-next-line no-param-reassign
        marketId = this.settings.market.id;
      } else {
        // eslint-disable-next-line no-param-reassign
        networkId = this.settings.tvNetworkId;
      }

      return this[type].tvNetworkWinners[marketId]
        ? this.indicadoresAudience(networkId, this[type].tvNetworkWinners[marketId]) : '';
    },

    setupClusterize() {
      this.clusterize = new Clusterize({
        rows: [],
        scrollId: 'scrollArea',
        contentId: 'contentArea',
        rows_in_block: 20,
        callbacks: {
          scrollingProgress: () => {
            this.selectScrollDirection();
            if (this.isScrollInBottom()) this.getScroll();
          },
        },
      });
    },
    setupCurrentAudienceOnTop() {
      const date = new Date();
      const startOfMinute = (60 - date.getSeconds()) * 1000;
      this.currentAudienceTimeout = setTimeout(() => {
        this.currentAudienceInterval = setInterval(() => {
          this.getData();
        }, 30000);
        this.getData();
      }, startOfMinute);
    },
    getData() {
      this.$store.dispatch('getProgramNowAverage');
      this.$store.dispatch('getDayAverages');
      this.$store.dispatch('getSelectedAverages');
      this.$store.dispatch('getLastSchedules');

      const { day, endTime } = this.settings.dateOptions;
      if (day > 0 || endTime) return;
      this.$store.dispatch('getAudiences');
    },
    indicadoresAudience(tvNetworkId, tvNetworkWinners) {
      let className = '';
      if (tvNetworkWinners.includes(tvNetworkId)) {
        className = tvNetworkWinners.length === 1 ? 'won' : 'drew';
      } else if (tvNetworkId === 'GLOBO') {
        className = 'lost';
      }
      return className;
    },
    columnTemplateHtml(value = '', tvNetworkId = '', className = '', kpi = '', length = 0) {
      return `<td tvNetworkId="${tvNetworkId}" class="${className}" style="min-width: ${this.calcWidth()}px">`
        .concat(value)
        .concat('<span class="kpi">')
        .concat(kpi)
        .concat('</span></td>');
    },
    populateAudiencePerNetwork(minuteLine, tvNetworkId, points, tvNetworkWinners) {
      // DANGER IS CHANGE THE VALUE INSIDE THE FUNCTION
      const networkInMinute = minuteLine.find(item => item.tv === tvNetworkId);

      if (networkInMinute) {
        // const { defaultValue } = this.settings;
        // Check status
        const tvNetwork = this.settings.isMarketView ? tvNetworkId : this.settings.tvNetworkId;
        const className = this.indicadoresAudience(tvNetwork, tvNetworkWinners);

        const value = this.dataTypeSelectedAsList.reduce((columnText, dataType, index, originalList) => {
          const value = this.formatValue(points[dataType], dataType); // audience or average
          return `${columnText}${value}${index === originalList.length -1 ? '' : ' | '}`;
        }, '');

        networkInMinute.points = points;
        networkInMinute.column = this.columnTemplateHtml(value, tvNetworkId, className, '2pts', this.dataTypeSelectedAsList.length);
      }
    },
    formatValue(value, dataType) {
      if (this.settings.isIndividualsView && dataType === 'audience') {
        return Intl.NumberFormat('pt-BR').format(value.toFixed(0));
      }
      return value.toFixed(2);
    },
    generateClusterizeTableView(minuteLines) {
      // iterate in evey minute to make table line -> | minute | tv1 | tv2 | tv3 | ...
      const rows = Object.keys(minuteLines).map((minute) => {
        if (minuteLines[minute] instanceof Object) {
          const tableLine = `<tr date="${minute}" ><td>${this.formatDateMS(minute)}</td>{{networkColumns}}</tr>`;
          const networkColumns = minuteLines[minute].reduce((acc, curr) => acc + curr.column, '');
          const tableLineView = tableLine.replace('{{networkColumns}}', networkColumns);
          return tableLineView;
        }
        return `<tr class="date">${minuteLines[minute]}</tr>`;
      });
      return rows;
    },
    mergeMinuteLineTable(newMinuteLineValue, oldMinuteTableValue) {
      if (!oldMinuteTableValue) return newMinuteLineValue;

      newMinuteLineValue.forEach((minute, index) => {
        if (minute.points !== '-') {
          oldMinuteTableValue[index] = minute;
        }
      });
      return oldMinuteTableValue;
    },
    updateMinuteLineTable(newMinuteLineValues) {
      // if there is no minuteLline return fresh one
      if (!this.minuteLines) return this.sortLineTable(newMinuteLineValues);
      // check if minuteTable is equal
      if (_.isEqual(this.minuteLines, newMinuteLineValues)) {
        return this.minuteLines;
      }
      // copy minuteLine and update values to trigger watcher
      const copy = JSON.parse(JSON.stringify(this.minuteLines));
      Object.keys(newMinuteLineValues).forEach((minute) => {
        copy[minute] = this.mergeMinuteLineTable(
          newMinuteLineValues[minute], this.minuteLines[minute],
        );
      });
      return this.sortLineTable(copy);
    },
    sortLineTable(newMinuteLineValues) {
      const sorted = Object.keys(newMinuteLineValues).sort();
      if (this.settings.isDescending) {
        sorted.reverse();
      }
      const ordered = sorted.reduce((acc, key) => {
        acc[key] = newMinuteLineValues[key];
        return acc;
      }, {});
      return ordered;
    },
    generateMinuteLines(audienceData, initialLinesPerMinute) {
      const lines = audienceData.reduce((acc, {
        date, tvNetworkId, marketId, tvNetworkWinners, sampling, ...rest
      }) => {
        const id = this.settings.isMarketView ? tvNetworkId : marketId;
        // trick - making a deep copy of small array
        const copy = JSON.parse(JSON.stringify(initialLinesPerMinute));
        acc[date] = acc[date] || copy;
        // fill the audience for the respected networkId per minute
        this.populateAudiencePerNetwork(acc[date], id, rest, tvNetworkWinners);
        this.populateSampling(acc[date], sampling);
        return acc;
      }, {});
      return lines;
    },
    populateSampling(minuteLine, sampling) {
      if (this.showSamplingColumn) {
        const networkInMinute = minuteLine.find(item => item.tv === 'sampling');
        networkInMinute.points = sampling;
        networkInMinute.column = `<td style="min-width: ${this.calcWidth()}px">${sampling}</td>`;
      }
    },
    syncAudienceDataIntoMinuteLineTable(data) {
      const networks = this.secondaryColumns.map(item => item.id);
      const initialColumnValue = `<td style="min-width: ${this.calcWidth()}px">-</td>`;
      const initialNetworkLinesPerMinute = networks.map(item => ({ tv: item, points: '-', column: initialColumnValue }));
      if (this.showSamplingColumn) {
        initialNetworkLinesPerMinute.push({ tv: 'sampling', points: '-', column: initialColumnValue });
      }
      const minuteLines = this.generateMinuteLines(data, initialNetworkLinesPerMinute);
      this.minuteLines = this.updateMinuteLineTable(minuteLines);
    },
    syncMinuteLineTableToClusterize(oldMinuteTable, newMinuteTable) {
      const oldMinuteTableKeys = _.keys(oldMinuteTable);
      const newMinuteTableKeys = _.keys(newMinuteTable);
      const diff = _.difference(newMinuteTableKeys, oldMinuteTableKeys);
      // update clusterize only if diff exists
      if (diff.length > 0) {
        const clusterizeNewData = this.generateClusterizeTableView(newMinuteTable);
        this.clusterize.update(clusterizeNewData);
      }
    },
    tbodyMouseClick(event) {
      if (!event.target.parentNode.attributes.date
        || !event.target.attributes.tvNetworkId
      ) return;
      const tvNetworkId = event.target.attributes.tvNetworkId.value;
      const dateStr = event.target.parentNode.attributes.date.value;
      const date = moment(dateStr).format();
      this.$modal.show('minuteDetails', { date, networkId: tvNetworkId });
    },
    tbodyMouseOver(event) {
      if (!event.target.parentNode.attributes.date) return;
      const dateStr = event.target.parentNode.attributes.date.value;

      const date = moment(dateStr);
      this.programsMouseOver = this.$store.getters.mapAllProgramsByDate(date);

      if (this.settings.usePrograms) {
        const schedules = this.schedules.filter(({ startDate, endDate }) => (
          moment(endDate).isSameOrAfter(dateStr)
          && moment(startDate).isSameOrBefore(dateStr)
        ));

        this.programsMouseOver = schedules.reduce((acc, { market, network, name }) => {
          acc[market] = acc[market] || {};
          acc[market][network] = name;
          return acc;
        }, this.programsMouseOver);
      }
    },
    tbodyMouseLeave() {
      this.programsMouseOver = null;
    },
    showProgramMouseOver(market, tvNetworkId) {
      if (this.settings.isMarketView) {
        const currentProgram = this.lastSchedules.filter(value => value.network === tvNetworkId);

        if (currentProgram.length !== 0) {
          return currentProgram[0].name;
        }

        return '--';
      }

      const currentProgram = this.lastSchedules.filter(value => value.market
              === market.exhibitionName);

      if (currentProgram.length !== 0) return currentProgram[0].name;

      if (tvNetworkId === 'GLOBO') {
        const globoProgram = this.lastSchedules.filter(value => value.market === 'SP1' || value.market === 'SP');
        if (globoProgram.length !== 0) return globoProgram[0].name;
      }

      return '--';
    },
    showProgramLastLine(market, tvNetworkId) {
      const { id, name } = market;
      if (id !== name && tvNetworkId !== 'GLOBO') return '--';

      return this.programsLastLine[name]
        ? this.programsLastLine[name][tvNetworkId] || '--'
        : '--';
    },
    calcWidth() {
      if (this.settings.isIndividualsView) {
        if (this.dataTypeSelectedAsList.includes('audience')) {
          return 100 + (this.dataTypeSelectedAsList.length - 1) * 60;
        }
      }
      return this.dataTypeSelectedAsList.length === 1
        ? 88
        : this.dataTypeSelectedAsList.length * 60;
    },
  },
};
</script>
