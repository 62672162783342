<template>
  <div class="dashboard">
    <div v-if="innerErrorOptions" class="error">
      <ErrorState :insides="innerErrorOptions.insides"
                  :title="innerErrorOptions.title"
                  :subtitle="innerErrorOptions.subtitle"
                  :clicked="innerErrorOptions.clicked"
                  :buttons="innerErrorOptions.buttons"
                  :fullscreen="false"
      />
    </div>
    <Vision v-else />
  </div>
</template>

<script>
import { event } from 'vue-gtag';
import { mapGetters } from 'vuex';
import Vision from './Dashboards/Vision.vue';
import ErrorState from '@/components/system/ErrorState.vue';

export default {
  components: {
    Vision,
    ErrorState,
  },
  computed: {
    ...mapGetters(['innerErrorOptions']),
  },
  beforeCreate() {
    // event('enter-page', 'Dashboards');
    this.$store.dispatch('selectDetailsView', 'dashboards');
  },
  mounted() {
    this.unwatch = this.$store.watch(state => state.settings, () => {
      this.$store.dispatch('hideError');
    }, { deep: true });
  },
  beforeDestroy() {
    this.unwatch();
    this.$store.dispatch('hideInnerError');
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';

.dashboard {
  position: relative;
  width: 100%;
  height: calc(100% - 96px);
  padding: 0;
  margin: 24px auto 0 auto;

  .loading_inner {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background: rgba($color-gray-lighter, 1);

    .spinner {
      position: relative;
      width: 40px;
      height: 40px;

      .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation:
          dash $duration ease-in-out infinite,
          colors ($duration*4) ease-in-out infinite;
      }
    }
  }


  .error {
    height: 100%;
  }

  .markets_full {
    display: none;
  }

  // Loading
  .market_vision, .rivals_vision {
    .card {
      &.disabled {
        pointer-events: none;
      }

      .loading {
        z-index: 2;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background-color: rgba($color-gray-lighter, 0.8);

        &.in {
          animation: fadeIn 0.4s ease-in-out forwards;
        }

        &.out {
          animation: fadeOut 0.4s ease-in-out forwards;
        }

        .loader {
          width: 24px;
          height: 24px;

          .spinner {
            width: 100%;
            height: 100%;
            animation: rotator $duration linear infinite;
          }

          .path {
            stroke-dasharray: $offset;
            stroke-dashoffset: 0;
            transform-origin: center;
            animation:
              dash $duration ease-in-out infinite,
              colors ($duration*4) ease-in-out infinite;
          }
        }
      }
      // ------
    }
  }

  // Market Vision
  .market_vision {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: space-between;
    width: 224px;
    height: 100%;

    .notch_icon {
      display: none;
    }

    .card {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      flex-grow: 1;
      height: auto;
      min-height: 32px;
      max-height: 48px;
      margin: 4px 0;

      &:first-child {
        margin: 0 0 4px 0;
      }

      &:last-child {
        margin: 4px 0 0 0;
      }

      // Alinhamento para os filhos
      .market_name,
      .progress_minutes,
      .market_average,
      .market_select {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        height: 100%;
      }

      // Market Name
      .market_name {
        width: 24px;
        font-size: 0.8em;
        font-style: italic;
        font-weight: 500;
        color: $color-gray-dark;
        margin: 0 0 0 4px;
        transition: all 0.4s ease-in-out;
      }

      .progress_minutes {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: calc(100% - 124px);
        background: $color-gray-lighter;
        margin: 0 8px;
        transition: all 0.4s ease-in-out;

        .item {
          flex-grow: 1;
          height: 100%;
          margin: 0;
          background-color: rgba($color-gray-light, 0.32);
          transition: background-color 0.4s ease-in-out;

          &.won {
            background-color: $color-status-won;
          }

          &.drew {
            background-color: $color-status-drew;
          }

          &.lost {
            background-color: $color-status-lost;
          }
        }
      }

      .market_average {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 40px;
        font-weight: 600;
        font-size: 0.88em;
        letter-spacing: 0;
        box-sizing: border-box;
        padding: 2px;
        margin: 0 4px;
        border-radius: 4px;
        color: $color-gray-lighter;
        background-color: $color-gray-lighter;
        transition: color 0.4s ease-in-out;

        &.won {
          color: $color-status-won;
        }

        &.drew {
          color: $color-status-drew;
        }

        &.lost {
          color: $color-status-lost;
        }
      }

      .market_select {
        width: 20px;
        margin: 0 4px;

        // Checkbox
        .round {
          position: relative;

          label {
            background-color: $color-gray-lighter;
            border: 2px solid rgba($color-gray-main, 0.24);
            border-radius: 100%;
            cursor: pointer;
            width: 20px;
            height: 20px;
            left: 0;
            position: absolute;
            top: 0;
            box-shadow: 0 4px 8px rgba($color-gray-darker, 0);
            transition: all 0.24s ease-in;

            &:hover {
              border: 2px solid rgba($color-gray-main, 0.56);
              background-color: $color-gray-back;
            }

            &:after {
              content: "";
              position: absolute;
              top: 3px;
              left: 3px;
              height: 10px;
              width: 10px;
              opacity: 0;
              border-radius: 100%;
              background-color: $color-gray-lighter;
              transition: all 0.24s ease-in;
            }
          }

          // Input
          input[type="radio"] {
            visibility: hidden;
          }

          input[type="radio"]:checked + label {
            background-color: $color-secondary;
            border-color: $color-secondary;
          }

          input[type="radio"]:checked + label:after {
            opacity: 1;
          }
        }
      }

      // Tratamento para card selecionado
      &.selected {
        box-shadow: 0 2px 8px rgba($color-gray-dark, 0.16);
        border-bottom: solid 2px $color-secondary;
        border-radius: 4px 4px 0 0;
      }

      // Tratamento para card hover
      &:hover {
        box-shadow: 0 2px 8px rgba($color-gray-dark, 0.16);
      }
    }
  }
  // ------------

  .market_vision,
  .rivals_vision {

    .card {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: center;
      width: 100%;
      min-height: 40px;
      height: auto;
      box-sizing: border-box;
      padding: 8px;
      border-radius: 4px;
      background-color: $color-gray-lighter;
      border-radius: 4px;

      font-family: $secondary-typo;
      font-weight: 400;
      font-size: 0.88em;

      cursor: pointer;
      transition: box-shadow 0.4s ease-in-out;
    }
  }

  // Rivals Vision
  .rivals_vision {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: space-between;
    width: 140px;
    height: 100%;
  }
}

// MEDIUM DEVICES
@media (max-width: 1200px) {
  .dashboard {
    margin: 8px 0 0 0;
    height: calc(100% - 64px);
  }
}

// Controle de Altura
@media (max-height: 800px) and (min-width: 610px) {
  .dashboard {

    .rivals_vision,
    .market_vision {
      display: block;
      overflow-y: auto;
    }
  }
}

// SMARTPHONES
@media (max-width: 600px) {
  .dashboard {
    width: 100vw;
    height: 100vh;
    margin: 0;
  }
}

</style>
