<template>
  <div class="content">
    <div class="topo">
      <button type="button" class="btn ripple" @click="goToPage('Settings')">
        <i class="icone material-icons">
          keyboard_arrow_left
        </i>
      </button>
      <div class="title">
        Praças Personalizadas
      </div>
    </div>
    <!-- // -->

    <div class="middle">
      <div class="description">
        Você pode ativar / desativar praças na lista abaixo,
        essa configuração será replicada em todo o app.
      </div>

      <!-- Painel // Configurações -->
      <div class="panel">
        <div class="title">
          <div class="text">
            Praças
          </div>
          <div class="line" />
        </div>

        <!-- Itens de navegação -->
        <draggable v-model="customProgramsMarketIdsEnabled"
                   :options="{
                     draggable:'.draggableItem',
                     emptyInsertThreshold: 20,
                     removeCloneOnHide: true,
                     animation: 80,
                     bubbleScroll: true,
                     ghostClass: 'ghost-background',
                     delay: 200,
                   }"
                   @start="drag=true" @end="drag=false"
        >
          <div v-for="market in customProgramsMarketsEnabled" :key="market.id"
               class="item draggableItem"
               @click="changeCustomProgramsMarketStatus(market.id)"
          >

            <span class="category">
              {{ market.exhibitionName }}
            </span>

            <label class="switch">
              <input :checked="true" type="radio">
              <span class="slider round ripple" />
            </label>
          </div>

          <!-- DISABLED -->
          <div v-for="market in customProgramsMarketsDisabled" :key="market.id"
               class="item"
               @click="changeCustomProgramsMarketStatus(market.id)"
          >
            <span class="category">
              {{ market.exhibitionName }}
            </span>

            <label class="switch">
              <input :checked="false" type="radio">
              <span class="slider round ripple" />
            </label>
          </div>
        </draggable>
      </div>
      <!-- // -->
    </div>
    <!-- // -->

    <div class="slice">
      <button type="button" class="btn btn_full btn_secondary ripple"
              @click="applyPreferences()"
      >
        Aplicar
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  data() {
    return {
      customProgramsMarketIdsEnabled: [],
    };
  },
  computed: {
    ...mapState(['system', 'settings', 'market']),
    ...mapGetters(['allTvNetworksId', 'tvNetworks']),
    customProgramsMarketsDisabled() {

      let marketsCustom = this.market.markets.map(object => ({ ...object }));
      
      marketsCustom = marketsCustom.filter(item => item.exhibitionName !== 'FLO');
      marketsCustom = marketsCustom.filter(item => item.exhibitionName !== 'POA');
      marketsCustom = marketsCustom.filter(item => item.exhibitionName !== 'MAN');
      marketsCustom = marketsCustom.filter(item => item.id !== 'RPT');
      marketsCustom = marketsCustom.filter(item => item.id !== 'RPP');
      marketsCustom = marketsCustom.filter(item => item.id !== 'FOT');
      marketsCustom = marketsCustom.filter(item => item.id !== 'RB');
      marketsCustom = marketsCustom.filter(item => item.id !== 'MAC');
      marketsCustom = marketsCustom.filter(item => item.id !== 'PVE');
      marketsCustom = marketsCustom.filter(item => item.id !== 'BVI');
      marketsCustom = marketsCustom.filter(item => item.id !== 'CAM');
      marketsCustom = marketsCustom.filter(item => item.id !== 'SOR');
      marketsCustom = marketsCustom.filter(item => item.id !== 'SJP');
      marketsCustom = marketsCustom.filter(item => item.id !== 'BAU');
      marketsCustom = marketsCustom.filter(item => item.id !== 'ITA');
      marketsCustom = marketsCustom.filter(item => item.id !== 'CAB');
      marketsCustom = marketsCustom.filter(item => item.id !== 'SLU');
      marketsCustom = marketsCustom.filter(item => item.id !== 'PNT');
      marketsCustom = marketsCustom.filter(item => item.id !== 'TBA');
      marketsCustom = marketsCustom.filter(item => item.id !== 'TBP');

      return [...marketsCustom.filter(
              market => this.customProgramsMarketIdsEnabled.indexOf(market.id) < 0,
      )];
    },
    customProgramsMarketsEnabled() {
     
      let marketsCustom = this.market.markets.map(object => ({ ...object }));
      
      marketsCustom = marketsCustom.filter(item => item.exhibitionName !== 'FLO');
      marketsCustom = marketsCustom.filter(item => item.exhibitionName !== 'POA');
      marketsCustom = marketsCustom.filter(item => item.exhibitionName !== 'MAN');
      marketsCustom = marketsCustom.filter(item => item.id !== 'RPT');
      marketsCustom = marketsCustom.filter(item => item.id !== 'RPP');
      marketsCustom = marketsCustom.filter(item => item.id !== 'FOT');
      marketsCustom = marketsCustom.filter(item => item.id !== 'RB');
      marketsCustom = marketsCustom.filter(item => item.id !== 'MAC');
      marketsCustom = marketsCustom.filter(item => item.id !== 'PVE');
      marketsCustom = marketsCustom.filter(item => item.id !== 'BVI');
      marketsCustom = marketsCustom.filter(item => item.id !== 'CAM');
      marketsCustom = marketsCustom.filter(item => item.id !== 'SOR');
      marketsCustom = marketsCustom.filter(item => item.id !== 'SJP');
      marketsCustom = marketsCustom.filter(item => item.id !== 'BAU');
      marketsCustom = marketsCustom.filter(item => item.id !== 'ITA');
      marketsCustom = marketsCustom.filter(item => item.id !== 'CAB');
      marketsCustom = marketsCustom.filter(item => item.id !== 'SLU');
      marketsCustom = marketsCustom.filter(item => item.id !== 'VIT');
      marketsCustom = marketsCustom.filter(item => item.id !== 'PNT');
      marketsCustom = marketsCustom.filter(item => item.id !== 'TBA');
      marketsCustom = marketsCustom.filter(item => item.id !== 'TBP');

      return [...this.customProgramsMarketIdsEnabled.map(
              marketId => marketsCustom.find(market => market.id === marketId),
      ).filter(market => market instanceof Object)];
    },
  },
  mounted() {
    this.$store.dispatch('hideMobileNavigation');
    this.defineEnableBtns();
  },
  beforeDestroy() {
    this.$store.dispatch('showMobileNavigation');
  },
  methods: {
    goToPage(name) {
      this.$router.push({ name });
    },
    applyPreferences() {
      this.customProgramsMarketIdsEnabled = this.customProgramsMarketsEnabled.map(({ id }) => id);
      this.$store.dispatch('setCustomProgramsMarketsEnabled', this.customProgramsMarketIdsEnabled);

      setTimeout(() => { // delay to not be too fast
        this.goToPage('Settings');
      }, 400);
    },
    defineEnableBtns() {
      this.customProgramsMarketIdsEnabled = [...this.settings.customProgramsMarkets];
    },
    changeCustomProgramsMarketStatus(marketId) {
      const index = this.customProgramsMarketIdsEnabled.indexOf(marketId);
      if (index > -1) {
        this.customProgramsMarketIdsEnabled.splice(index, 1);
      } else {
        this.customProgramsMarketIdsEnabled.push(marketId);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

  @import '@/assets/scss/variables.scss';

  // SMARTPHONES
  @media (max-width: 600px) {

    .content {
      position: relative;
      z-index: 999;
      display: block;
      width: 100vw;
      height: 100vh;
      opacity: 0;
      animation: fadeIn 0.8s ease-in-out forwards;

      .topo {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 64px;
        background: linear-gradient(140deg, $color-primary-light, $color-secondary);
        background-size: 100% 100%;
        box-shadow: 0 8px 16px rgba($color-gray-darker, 0.16);

        // Botão
        .btn {
          width: 56px;
          height: 56px;
          margin: 0;
          padding: 0;
          box-shadow: none;

          i {
            margin: 4px 0 0 0;
            padding: 0;
          }

          // Ripple Effect
          &.ripple {
            background-position: center;
            background: rgba($color-gray-back, 0);
            transition: background 0.56s ease-in-out, opacity 0.8s ease-in-out;

            & i {
              color: $color-gray-lighter;
              transition: color 0.56s ease-in-out;
            }

            &:hover {
              background: rgba($color-gray-back, 0.04) radial-gradient(circle, transparent 1%, rgba($color-gray-back, 0.04) 1%) center/15000%;
              color: $color-gray-main;

              & i {
                color: $color-gray-lighter;
              }
            }

            &:active {
              background-color: rgba($color-gray-lighter, 0.04);
              background-size: 100%;
              transition: background 0s;
            }
          }
        }
        // ------------

        .title {
          font-family: $secondary-typo;
          font-weight: 500;
          font-size: 0.88em;
          text-transform: uppercase;
          color: $color-gray-lighter;
          margin: 0 0 0 8px;
        }
      }
      // ---------------

      .middle {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
        box-sizing: border-box;
        padding: 0 0 156px 0;
        overflow-y: auto;
        background-color: $color-gray-back;

        // Description
        .description {
          position: relative;
          display: inline-block;
          box-sizing: border-box;
          padding: 16px;
          width: 100%;
          margin: 8px 0 16px 0;
          font-family: $secondary-typo;
          font-size: 0.88em;
          font-weight: 400;
          font-style: italic;
          text-align: justify;
          color: $color-gray-dark;

          &::after {
            content: "";
            display: block;
            width: 24px;
            height: 4px;
            margin: 16px 0 0 0;
            background-color: rgba($color-gray-light, 0.4);
          }
        }
        // --------------

        // Panel
        .panel {
          display: block;
          width: calc(100% - 32px);
          box-sizing: border-box;
          padding: 8px 0;
          margin: 0 auto 0 auto;
          background: $color-gray-lighter;
          border-radius: 4px;
          // box-shadow: 0 4px 16px rgba($color-gray-darker, 0.08);
          // opacity: 0;
          // animation-delay: 1s !important;
          // animation: fadeInRight 1s ease-in-out forwards;

          .title {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin: 0 0 16px 0;
            padding: 8px 8px 16px 16px;
            border-bottom: solid 1px rgba($color-gray-light, 0.24);

            .text {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              font-family: $primary-typo;
              font-size: 0.72em;
              font-weight: 500;
              text-transform: uppercase;
              letter-spacing: 0.08em;
              color: $color-gray-dark;

              i {
                font-size: 1.4em;
                margin: 0 8px 0 8px;
                color: $color-gray-light;
              }
            }
          }

          // Itens
          .item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            padding: 8px 16px;

            font-family: $secondary-typo;
            font-size: 0.88em;
            font-weight: 400;

            span {
              pointer-events: none;
            }

            .category {
              background-color: $color-gray-back;
              padding: 4px;
              width: 48px;
              margin-right: 16px;
              text-align: center;
              font-weight: 600;
              font-size: 0.9em;
              color: $color-gray-main;
            }

            // Switch
            .switch {
              position: relative;
              display: inline-block;
              margin-left: auto;
              width: 48px;
              height: 28px;
              pointer-events: none;

              input {
                opacity: 0;
                width: 0;
                height: 0;
              }

              .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $color-gray-light;
                transition: .4s;

                &::before {
                  position: absolute;
                  content: "";
                  height: 22px;
                  width: 22px;
                  left: 3px;
                  bottom: 3px;
                  border-radius: 100%;
                  background-color: $color-gray-lighter;
                  box-shadow: 0 4px 16px rgba($color-gray-darker, 0.24);
                  transition: .4s;
                }

                /* Rounded sliders */
                &.round {
                  border-radius: 34px;
                }
              }

              input:checked + .slider {
                background-color: $color-secondary;
              }

              input:checked + .slider::before {
                transform: translateX(20px);
              }
            }
          }
        }
      }
      // ---------------

      .slice {
        position: fixed;
        z-index: 3;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 56px;
        background-color: $color-gray-lighter;

        .btn {
          border: none;

          &:hover {
            border: none;
          }
        }
      }
      // ---------------
    }

  }

</style>
