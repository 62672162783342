<template>
  <div v-if="isMarketView" class="tags">
    <button
      v-for="market in markets"
      :key="market.id"
      type="button"
      class="btn ripple tag"
      :class="buttonMarketCss(market.id)"
      @click="selectMarket(market)"
    >
      {{ market.exhibitionName }}
      <span v-if="settings.isMarketsWeightVisible" :title="market.id === 'RPP' ? 'Posse' : 'Peso'" class="tag-weight">
        {{ market.weight ? market.weight : '-' }}
      </span>
    </button>
  </div>

  <div v-else class="tags">
    <button
      v-for="tvNetwork in tvNetworks"
      :key="tvNetwork.id"
      type="button"
      class="btn ripple tag"
      :class="buttonTvNetworkCss(tvNetwork.id)"
      @click="selectTvNetworkId(tvNetwork.id)"
    >
      <img v-if="getNetworkLogo(tvNetwork.id)" :src="getNetworkLogo(tvNetwork.id)" />
      <div
        v-else
        :style="{ color: getNetworkColor(tvNetwork.id) }"
      >{{ transNetworkNameById(tvNetwork.id) }}</div>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import transformMixin from "@/utils/transformMixin";

export default {
  mixins: [transformMixin],
  props: {
    marketView: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["markets", "tvNetworks", "tvNetworks_v2"]),
    isMarketView() {
      return this.marketView === null
        ? this.settings.isMarketView
        : this.marketView;
    },
  },
  methods: {
    getMarketSelected() {
      if (this.settings.isSprintView) {
        if (
          //this.settings.market.id === "PNT" ||
          this.settings.market.id === "RPT" ||
          this.settings.market.id === "RPP"
        ) {
          return this.settings.marketIdOrder[0];
        } else {
          return this.settings.market.id;
        }
      } else {
        return this.settings.market.id;
      }
    },

    getNetworkLogo(networkId) {
      return this.tvNetworks_v2[networkId] &&
        this.tvNetworks_v2[networkId].customLogoPath
        ? this.tvNetworks_v2[networkId].customLogoPath
        : "";
    },
    getNetworkColor(networkId) {
      return this.tvNetworks_v2[networkId] &&
        this.tvNetworks_v2[networkId].customColor
        ? this.tvNetworks_v2[networkId].customColor
        : "";
    },
    selectMarket(market) {
      this.$store.dispatch("selectMarket", market);
      this.$store.dispatch("selectIsImportActivate", false);
    },
    selectTvNetworkId(tvNetworkId) {
      this.$store.dispatch('selectTvNetworkId', tvNetworkId);
      this.$store.dispatch('selectIsImportActivate', false);
    },
    buttonMarketCss(id) {
      return {
        //enabled: (id === this.settings.market.id),
        enabled: id === this.getMarketSelected(),
        custom_program_active:
          this.settings.customProgramsMarkets.includes(id) &&
          this.settings.usePrograms
      };
    },
    buttonTvNetworkCss(id) {
      return {
        enabled: id === this.settings.tvNetworkId
      };
    }
  }
};
</script>

